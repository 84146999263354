import { AllowedTimelineTypes, FormTab, TabItem } from "@amenda-types";
import {
  EditProjectMedia,
  ProjectFormsWrapper,
  ProjectTemplateDesigner,
} from "@amenda-components/Projects";
import { FC, ReactNode } from "react";

import { CreateOrEditProjectCostGroups } from "@amenda-components/CostGroup/CreateOrEditProjectCostGroups";
import { EditProjectConstructionDetails } from "./EditProjectConstructionDetails";
import { Timeline } from "@amenda-components/Timeline";
import { isEmpty } from "lodash";
import { staticProjectLabels } from "@amenda-components/Projects/common";

interface Props {
  defaultTab: string;
  projectId?: string;
  projectForms: FormTab[];
  projectFormTabs: TabItem[];
}

const showCurrentTab = ({
  tab,
  projectId,
  projectForms,
}: {
  tab: TabItem;
  projectForms: FormTab[];
  projectId?: string;
}) => {
  const tabComponents: Record<string, ReactNode> = {
    [staticProjectLabels.constructionDetails.value]: (
      <EditProjectConstructionDetails projectId={projectId} />
    ),
    [staticProjectLabels.attachments.value]: (
      <div className="amenda-form pt-5">
        <EditProjectMedia projectId={projectId} />
      </div>
    ),
    [staticProjectLabels.designs.value]: (
      <ProjectTemplateDesigner projectId={projectId} />
    ),
    [staticProjectLabels.costGroups.value]: (
      <CreateOrEditProjectCostGroups projectId={projectId} />
    ),
    [staticProjectLabels.activity.value]: (
      <Timeline type={AllowedTimelineTypes.Project} resourceId={projectId} />
    ),
  };
  projectForms.forEach((form) => {
    tabComponents[form.id] = <ProjectFormsWrapper form={form} />;
  });

  return tabComponents[tab.value];
};

export const ProjectFormTabs: FC<Props> = ({
  projectId,
  defaultTab,
  projectForms,
  projectFormTabs,
}) => {
  const foundIndex = projectFormTabs.findIndex(
    (tab) => tab.value === defaultTab,
  );
  const defaultTabIndex = foundIndex >= 0 ? foundIndex : 0;

  if (isEmpty(projectForms)) {
    return null;
  }
  return (
    <div className="pt-4">
      {showCurrentTab({
        projectId,
        projectForms,
        tab: projectFormTabs[defaultTabIndex],
      })}
    </div>
  );
};
