import { KeyingConfig } from "@urql/exchange-graphcache";

export const keys: KeyingConfig = {
  Address: () => null,
  CostGroupValues: () => null,
  PaginatedProject: () => null,
  PaginatedSystemRole: () => null,
  PaginatedCostGroup: () => null,
  ProjectCostEstimate: () => null,
  PaginatedUser: () => null,
  PaginatedContact: () => null,
  PaginatedMaterial: () => null,
  PaginatedAttachment: () => null,
  PaginatedRegionalFactor: () => null,
  TenantRoot: () => null,
  NeighborProject: () => null,
  ProjectCostAggregate: () => null,
  PaginatedImageDescriptor: () => null,
  PaginatedTimelineActivity: () => null,
  Share: () => null,
  SearchResult: () => null,
  CommonObjectsValuesResult: () => null,
  CollectionShare: () => null,
};
