import {
  BuildingOfficeIcon,
  CubeIcon,
  CurrencyEuroIcon,
  PhotoIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Tab, TabGroup, TabList } from "@headlessui/react";

import { ConstructionIcon } from "lucide-react";
import { FC } from "react";
import { FormCategories } from "@amenda-constants";
import clsx from "clsx";
import { getCategorySidebar } from "./common";
import { useAppStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

const Icons: FC<{ value?: FormCategories }> = ({ value }) => {
  switch (value) {
    case FormCategories.Projects:
      return <BuildingOfficeIcon className="w-5 h-5" />;
    case FormCategories.Media:
      return <PhotoIcon className="w-5 h-5" />;
    case FormCategories.ConstructionDetails:
      return <ConstructionIcon className="w-5 h-5" />;
    case FormCategories.UnitPrice:
      return <CubeIcon className="w-5 h-5" />;
    case FormCategories.CostGroups:
      return <CurrencyEuroIcon className="w-5 h-5" />;
    default:
      return <UsersIcon className="w-5 h-5" />;
  }
};

export const FormBuilderCategorySidebar: FC = () => {
  const { t } = useTranslation();
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const formBuilderState = useAppStore((state) => state.formBuilderState);

  const { selectedForm } = formBuilderState ?? {};
  const selectedCategory =
    selectedForm &&
    getCategorySidebar(true).find((category) =>
      selectedForm.category?.startsWith(category.value),
    );
  const isContactCategory =
    selectedCategory &&
    selectedCategory.value.includes(FormCategories.Contacts);

  const onClick = (value: string) => () => {
    updateFormBuilderState("selectedCategory", value);
    if (value !== selectedCategory?.value) {
      updateFormBuilderState("selectedForm", undefined);
    }
  };

  return (
    <div className="h-full w-full px-2">
      <TabGroup vertical>
        <TabList className="space-y-1">
          {getCategorySidebar().map((category) => (
            <Tab
              key={category.value}
              className={clsx(
                "w-full px-3 py-2 cursor-pointer text-sm rounded-sm outline-none",
                {
                  "bg-gray-800 text-white":
                    selectedCategory &&
                    selectedCategory?.value === category.value,
                  "text-gray-800 hover:bg-gray-800 hover:text-white":
                    !Boolean(selectedCategory?.value) ||
                    selectedCategory?.value !== category.value,
                },
              )}
              onClick={onClick(category.value)}
            >
              <div className="w-full flex items-center">
                <Icons value={category.value} />
                <span className="ml-2 truncate">{t(category.label)}</span>
              </div>
            </Tab>
          ))}
          <Tab
            disabled
            className="w-full px-3 py-2 cursor-pointer text-sm rounded-sm outline-none border-t border-gray-300"
          >
            <div className="w-full flex items-center">
              <UsersIcon className="w-5 h-5" />
              <span className="ml-2 truncate">{t("Users")}</span>
            </div>
          </Tab>
          <Tab
            className={clsx(
              "w-full px-3 py-2 cursor-pointer text-sm rounded-sm outline-none",
              {
                "bg-gray-800 text-white":
                  selectedCategory &&
                  selectedCategory.value === FormCategories.OfficeContacts,
                "text-gray-800 hover:bg-gray-800 hover:text-white":
                  !isContactCategory,
              },
            )}
            onClick={onClick(FormCategories.OfficeContacts)}
          >
            <div className="w-full flex items-center">
              <span className="ml-7 truncate">{t("Office")}</span>
            </div>
          </Tab>
          <Tab
            className={clsx(
              "w-full px-3 py-2 cursor-pointer text-sm rounded-sm outline-none",
              {
                "bg-gray-800 text-white":
                  selectedCategory &&
                  selectedCategory.value === FormCategories.PersonsContacts,
                "text-gray-800 hover:bg-gray-800 hover:text-white":
                  !isContactCategory,
              },
            )}
            onClick={onClick(FormCategories.PersonsContacts)}
          >
            <div className="w-full flex items-center">
              <span className="ml-7 truncate">{t("Persons")}</span>
            </div>
          </Tab>
          <Tab
            className={clsx(
              "w-full px-3 py-2 cursor-pointer text-sm rounded-sm outline-none",
              {
                "bg-gray-800 text-white":
                  selectedCategory &&
                  selectedCategory.value === FormCategories.CompanyContacts,
                "text-gray-800 hover:bg-gray-800 hover:text-white":
                  !isContactCategory,
              },
            )}
            onClick={onClick(FormCategories.CompanyContacts)}
          >
            <div className="w-full flex items-center">
              <span className="ml-7 truncate">{t("Company")}</span>
            </div>
          </Tab>
          <Tab
            className={clsx(
              "w-full px-3 py-2 cursor-pointer text-sm rounded-sm outline-none",
              {
                "bg-gray-800 text-white":
                  selectedCategory &&
                  selectedCategory.value === FormCategories.Contacts,
                "text-gray-800 hover:bg-gray-800 hover:text-white":
                  !isContactCategory,
              },
            )}
            onClick={onClick(FormCategories.Contacts)}
          >
            <div className="w-full flex items-center">
              <span className="ml-7 truncate">{t("Other")}</span>
            </div>
          </Tab>
        </TabList>
      </TabGroup>
    </div>
  );
};
