import { AddComponentButton, SortItem } from "./DragAndDropWrapper";
import { GripIcon, PencilIcon } from "lucide-react";

import { FC } from "react";
import { GridWrapper } from "./GridWrapper";
import { IconButtonBase } from "@amenda-components/App";
import { LayoutProps } from "./common";
import clsx from "clsx";
import { isChildrenEmpty } from "@amenda-utils";
import { useAppStore } from "@amenda-domains/mutations";

export const FullColumnGrid: FC<LayoutProps> = ({
  children,
  isFormBuilder,
  config,
}) => {
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );

  const handleEdit = () => {
    updateFormBuilderState("openEditComponentModal", true);
    updateFormBuilderState("selectedFormComponent", {
      config,
    });
  };

  return (
    <SortItem
      className="col-span-6 group/full-col-grid"
      config={config}
      isFormBuilder={isFormBuilder}
      showBorder={isChildrenEmpty(children)}
    >
      {isFormBuilder && (
        <div
          className={clsx("relative flex items-center space-x-2 pb-2", {
            "invisible group-hover/full-col-grid:visible":
              !isChildrenEmpty(children),
          })}
        >
          <IconButtonBase
            size="sm"
            className="cursor-grab px-0.5 py-1"
            variant="clean"
          >
            <GripIcon className="w-5 h-5 stroke-[2]" />
          </IconButtonBase>
          <IconButtonBase
            size="sm"
            className="p-1"
            variant="clean"
            onClick={handleEdit}
          >
            <PencilIcon className="w-5 h-5" />
          </IconButtonBase>
          <AddComponentButton
            className="absolute top-0 right-0 w-full flex justify-end -mt-7 !-mr-2 z-20"
            isFormBuilder={isFormBuilder}
            config={config}
          />
        </div>
      )}
      <GridWrapper isFormBuilder={isFormBuilder} config={config}>
        {children}
      </GridWrapper>
    </SortItem>
  );
};
