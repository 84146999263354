import { ExchangeIO, ExchangeInput } from "urql";
import { empty, fromPromise, map, mergeMap, pipe } from "wonka";
import { getIdTokenInUrl, getPdfToken } from "@amenda-utils";
import { loginRequest, msalInstance } from "@amenda-domains/msGraphConfig";

import { AccountInfo } from "@azure/msal-browser";
import { processToken } from "./mutations";

const getToken = async () => {
  let token = await getPdfToken();
  if (token) return token;

  const decodedToken = getIdTokenInUrl();
  token = await processToken(decodedToken);
  return token;
};

const getMsAccessToken = async () => {
  // Silently acquires an access token which is then attached to a request for MS Graph data
  if (!msalInstance.getActiveAccount()) return "";
  return await msalInstance
    .acquireTokenSilent({
      ...loginRequest,
      account: msalInstance.getActiveAccount() as AccountInfo,
    })
    .then((response: any) => response.accessToken);
};

const getHeaders = async () => {
  const token = await getToken();
  const msAccessToken = await getMsAccessToken();

  return {
    authorization: token ? `Bearer ${token}` : "",
    "MS-GRAPH-TOKEN": msAccessToken ? msAccessToken : "",
  };
};

export const asyncHeadersExchange = ({
  forward,
}: ExchangeInput): ExchangeIO => {
  return (operations$) => {
    return pipe(
      operations$,
      mergeMap((operation) => {
        if (operation.kind === "teardown") {
          return pipe(
            empty,
            map(() => operation),
          );
        }
        return pipe(
          fromPromise(getHeaders()),
          map((headers) => {
            if (!operation.context.fetchOptions) {
              operation.context.fetchOptions = {
                credentials: "include",
                headers: {},
              };
            }
            if (typeof operation.context.fetchOptions === "function") {
              return operation;
            }

            Object.assign(operation.context.fetchOptions.headers || {}, {
              ...(operation.context.fetchOptions.headers || {}),
              ...(operation.context.customHeaders || {}),
              ...headers,
            });
            return operation;
          }),
        );
      }),
      forward,
    );
  };
};
