import { Option, PageComponentProps, availableLayouts } from "@amenda-types";

import { FormComponentTypes } from "@amenda-constants";
import Fuse from "fuse.js";
import clsx from "clsx";
import { useCallback } from "react";

interface HandleOptionsProps {
  option: Option;
  selectedOptions: string[];
  parentId?: string;
  onChange?: (options: string[]) => void;
}

const getChildrenFromOption = (option: Option): Option[] => {
  const children: Option[] = [];
  const stack: Option[] = [option];

  while (stack.length > 0) {
    const currentOption = stack.pop()!;
    if (currentOption.children && currentOption.children.length > 0) {
      currentOption.children.forEach((child) => {
        children.push(child);
        stack.push(child);
      });
    }
  }

  return children;
};

export const onChangeOptions = ({
  option,
  parentId,
  selectedOptions,
  onChange,
}: HandleOptionsProps) => {
  const children = getChildrenFromOption(option);
  const parentAndChildrenIds = [option.value, ...children.map((c) => c.value)];

  if (selectedOptions.some((o) => o === option.value)) {
    let values = selectedOptions.filter(
      (o) => !parentAndChildrenIds.includes(o),
    );
    values = values.filter((o) => o !== String(parentId));

    onChange?.(values);
  } else {
    const values = selectedOptions.filter((o) => o !== String(parentId));

    onChange?.([...values, ...parentAndChildrenIds]);
  }
};

export const expandComponentGroup = ({
  index,
  component,
  level,
  components,
}: {
  index: number;
  component: any;
  level: number;
  components: any[];
}) => {
  const firstHalf = components.slice(0, index + 1);
  const secondHalf = components.slice(index + 1);
  return [
    ...firstHalf,
    ...component.components.map((c: any) => ({
      ...c,
      level: level + 1,
    })),
    ...secondHalf,
  ];
};

const getVisibleIdsByComponent = (
  component: any,
  visibleComponentIds: string[],
) => {
  visibleComponentIds.push(component.id);
  if (component.components) {
    component.components.forEach((c: any) => {
      getVisibleIdsByComponent(c, visibleComponentIds);
    });
  }
  return visibleComponentIds;
};

export const collapseComponentGroup = ({
  component,
  components,
}: {
  component: any;
  components: any[];
}) => {
  const componentAndChildrenIds = getVisibleIdsByComponent(component, []);
  const childrenIds = componentAndChildrenIds.filter(
    (id) => id !== component.id,
  );
  const visibleComponents = components.filter(
    (component) => !childrenIds.includes(component.id),
  );
  return {
    closedComponent: {
      childrenIds,
      visibleComponents,
    },
  };
};

export const expandAllComponentsInGroup = ({
  index,
  component,
  components: groupComponents,
  visibleComponents,
  expandedComponentGroup,
}: {
  index: number;
  component: any;
  components: any[];
  visibleComponents: any[];
  expandedComponentGroup: Record<string, boolean>;
}) => {
  const firstHalf = visibleComponents.slice(0, index + 1);
  const secondHalf = visibleComponents.slice(index + 1);
  const updateExpandedComponentGroups = { ...expandedComponentGroup };
  const components = [
    ...firstHalf,
    ...groupComponents.filter(
      (component) => !expandedComponentGroup[component.id],
    ),
    ...secondHalf,
  ];

  updateExpandedComponentGroups[component.id] = true;

  groupComponents.forEach((component) => {
    updateExpandedComponentGroups[component.id] = true;
  });

  return {
    components,
    updateExpandedComponentGroups,
  };
};

interface ToggleComponentGroups {
  index: number;
  component: any;
  searchTerm: string;
  visibleComponents: any[];
  expandedComponentGroup: Record<string, boolean>;
  searchComponents: (searchTerm: any) => {
    visibleComponents: any[];
    expandedComponentGroup: Record<string, boolean>;
  };
}

export const toggleComponentGroups = ({
  index,
  component,
  searchTerm,
  visibleComponents,
  expandedComponentGroup,
  searchComponents,
}: ToggleComponentGroups) => {
  const level = component?.level || 0;
  const isComponentExpanded = Boolean(expandedComponentGroup[component?.id]);
  let components = [];
  let updateExpandedComponentGroups: Record<string, boolean> = {
    ...expandedComponentGroup,
    [component.id]: !isComponentExpanded,
  };

  if (isComponentExpanded) {
    const { closedComponent } = collapseComponentGroup({
      component,
      components: visibleComponents,
    });
    components = closedComponent.visibleComponents;
    closedComponent.childrenIds.forEach((componentId) => {
      updateExpandedComponentGroups[componentId] = false;
    });
  } else if (searchTerm) {
    const { visibleComponents, expandedComponentGroup } =
      searchComponents(searchTerm);
    components = visibleComponents;
    updateExpandedComponentGroups = expandedComponentGroup;
  } else {
    components = expandComponentGroup({
      level,
      index,
      component,
      components: visibleComponents,
    });
  }

  return { components, updateExpandedComponentGroups };
};

export const groupComponentsById = (
  components: any,
  componentsById: Record<string, any>,
) => {
  components.forEach((component: any) => {
    componentsById[component.id] = component;
    if (component.components) {
      groupComponentsById(component.components, componentsById);
    }
  });
  return componentsById;
};

export const unfoldComponents = (components: any[], allComponents: any[]) => {
  components.forEach((c) => {
    allComponents.push(c);
    if (c.components) {
      unfoldComponents(c.components, allComponents);
    }
  });
  return allComponents;
};

export const getLeafNodeComponents = (components: any[], level: number) => {
  const allComponents: any[] = [];
  const stack = [...components];

  while (stack.length > 0) {
    const component = stack.pop();
    const currComponent = { ...component, level: component?.level || level };

    if (currComponent.components) {
      stack.push(
        ...currComponent.components.map((c: any) => {
          return {
            ...c,
            level: currComponent.level + 1,
          };
        }),
      );
    }
    allComponents.push(currComponent);
  }
  return allComponents;
};

export const findMatchingComponents = (
  components: any[],
  searchTerm: string,
) => {
  const fuse = new Fuse(components, {
    includeScore: true,
    shouldSort: true,
    threshold: 0.3,
    keys: [
      {
        name: "label",
        getFn: (component: any) =>
          (component.properties?.label as string) || "",
      },
      {
        name: "placeholder",
        getFn: (component: any) =>
          (component.properties?.placeholder as string) || "",
      },
    ],
  });

  const results = fuse.search(searchTerm);
  return results.map(({ item }) => item);
};

const sortComponents = (components: any[]) => {
  return components.sort((a, b) => {
    const aVal = a?.properties?.code || a.order;
    const bVal = b?.properties?.code || b.order;

    return parseInt(aVal) - parseInt(bVal);
  });
};

const generateComponentTree = (
  components: any[],
  componentsById: Record<string, any>,
) => {
  const stack = [...components];
  const tree: Record<string, any> = {};
  const expandedComponentGroup: Record<string, boolean> = {};

  while (stack.length > 0) {
    const component = stack.pop();
    const parentComponent = componentsById[component.parentId];

    if (parentComponent) {
      stack.push(parentComponent);
      expandedComponentGroup[parentComponent.id] = true;
    }

    const id = component.id;
    tree[id] = {
      ...component,
      level: !parentComponent ? 0 : undefined,
    };
  }

  return { tree, expandedComponentGroup };
};

const addToIndex = ({
  component,
  parentComponent,
  visibleComponents,
  componentParentIndex,
}: {
  component: any;
  parentComponent: any;
  visibleComponents: any[];
  componentParentIndex: Record<string, number>;
}) => {
  const index = componentParentIndex[parentComponent.id];
  const firstHalf = visibleComponents.slice(0, index + 1);
  const secondHalf = visibleComponents.slice(index + 1);
  const parentLevel = visibleComponents[index]?.level || 0;

  componentParentIndex[component.id] = index + 1;
  secondHalf.forEach((c) => {
    componentParentIndex[c.id] = componentParentIndex[c.id] + 1;
  });

  return [
    ...firstHalf,
    {
      ...component,
      level: parentLevel + 1,
    },
    ...secondHalf,
  ];
};

const generateVisibleComponents = (tree: Record<string, any>) => {
  let visibleComponents: any[] = [];
  const components = sortComponents(Object.values(tree));
  const queue = [...components];
  const componentParentIndex: Record<string, number> = {};

  while (queue.length > 0) {
    const component = queue.shift();
    const parentComponent = tree[component.parentId];

    if (!parentComponent) {
      componentParentIndex[component.id] = visibleComponents.length;
      visibleComponents.push(component);
    } else if (Number.isFinite(componentParentIndex[parentComponent.id])) {
      visibleComponents = addToIndex({
        component,
        parentComponent,
        visibleComponents,
        componentParentIndex,
      });
    } else {
      queue.push(component);
    }
  }

  return { visibleComponents };
};

const processSearchResults = (
  components: any[],
  componentsById: Record<string, any>,
) => {
  const { tree, expandedComponentGroup } = generateComponentTree(
    components,
    componentsById,
  );
  const { visibleComponents } = generateVisibleComponents(tree);

  return { visibleComponents, expandedComponentGroup };
};

export const useSearchComponents = (
  allComponents: any[],
  componentsById: Record<string, any>,
) => {
  const searchComponents = useCallback(
    (searchTerm: string) => {
      const matchingComponents = findMatchingComponents(
        allComponents,
        searchTerm,
      );
      return processSearchResults(matchingComponents, componentsById);
    },
    [allComponents, componentsById],
  );

  return searchComponents;
};

const isFormFieldObjectDirty = (dirtyField: Record<string, any>) => {
  return Object.values(dirtyField).some((value) => Boolean(value));
};

const isFormFieldArrayDirty = (dirtyField: any[]) => {
  return dirtyField.some((field) => {
    if (typeof field === "object") {
      return isFormFieldObjectDirty(field);
    }
    return Boolean(field);
  });
};

export const getDirtyFieldsValue = (
  dirtyFields: Record<
    string,
    boolean | Record<string, boolean>[] | Record<string, boolean>
  >,
  data: Record<string, any>,
) => {
  const values: Record<string, any> = {};
  const dirtyFieldIds: string[] = [];
  Object.keys(dirtyFields).forEach((key) => {
    const dirtyField = dirtyFields[key];
    if (Array.isArray(dirtyField)) {
      if (isFormFieldArrayDirty(dirtyField)) {
        dirtyFieldIds.push(key);
      }
      return;
    }
    if (typeof dirtyField === "object") {
      if (isFormFieldObjectDirty(dirtyField)) {
        dirtyFieldIds.push(key);
      }
      return;
    }
    if (Boolean(dirtyFields[key])) {
      dirtyFieldIds.push(key);
    }
  });

  dirtyFieldIds.forEach((key) => {
    values[key] = data[key];
  });
  return values;
};

export const getHeaderLayoutClasses = (component: PageComponentProps) => {
  const layout = component?.headerProperties?.layout;

  return clsx("col-span-6", {
    "md:col-span-3": layout === availableLayouts.halfColumn,
    "md:col-span-2": layout === availableLayouts.oneThirdColumn,
    "md:col-span-4": layout === availableLayouts.twoThirdColumn,
  });
};

export const enrichHeaderComponentDefaults = (
  components: PageComponentProps[],
  isFormBuilder = false,
) => {
  return components.map((component) => {
    if (component.headerProperties) {
      const defaultLayout = isFormBuilder
        ? availableLayouts.halfColumn
        : [
              FormComponentTypes.RegionalSelect,
              FormComponentTypes.Select,
              FormComponentTypes.MultiSelect,
            ].includes(component.component as FormComponentTypes)
          ? availableLayouts.halfColumn
          : availableLayouts.fullColumn;

      return {
        ...component,
        headerProperties: {
          ...component.headerProperties,
          layout: component.headerProperties.layout || defaultLayout,
        },
      };
    }
    return component;
  });
};
