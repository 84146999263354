import { FC, ReactNode } from "react";

import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

interface Props {
  children: ReactNode;
}

export const SettingsLayout: FC<Props> = ({ children }) => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet title={t("Settings")} />
      <div className="flex justify-center">
        <div className="grid grid-cols-1 w-full">
          <div className="bg-white overflow-hidden pb-5 rounded sm:rounded-lg py-1 px-4 lg:py-6">
            <div>{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};
