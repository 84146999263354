import { AllowedContactType, AppRoutes } from "@amenda-types";
import { Avatar, HoverListSelector } from "@amenda-components/App";
import { flattenUserDetails, getUserName, isSelectedUser } from "./common";
import { useNavigate, useParams } from "react-router-dom";

import { BuildingOfficeIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import clsx from "clsx";
import { useUsersStore } from "@amenda-domains/mutations";

interface Props {
  contact: any;
  isCollection?: boolean;
}

export const ContactListCard: FC<Props> = ({ contact }) => {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>();
  const setSelectedUserId = useUsersStore((state) => state.setSelectedUserId);
  const toggleSelectedUser = useUsersStore((state) => state.toggleSelectedUser);
  const selectedUsers = useUsersStore((state) => state.selectedUsers);
  const setShiftSelectedUser = useUsersStore(
    (state) => state.setShiftSelectedUser,
  );
  const setOpenContactListSlideOver = useUsersStore(
    (state) => state.setOpenContactListSlideOver,
  );

  const user = flattenUserDetails(contact);
  const name = getUserName(user);

  const handleClick = () => {
    if (userId) {
      navigate(AppRoutes.Contacts);
    }
    setSelectedUserId(contact);
    setOpenContactListSlideOver(false);
  };

  const handleSelect = () => {
    toggleSelectedUser(contact);
  };

  const handleShiftSelection = () => {
    setShiftSelectedUser(contact);
  };

  return (
    <HoverListSelector
      isSelected={isSelectedUser(selectedUsers ?? [], contact)}
      className="w-full cursor-pointer"
      handleSelect={handleSelect}
      handleClick={handleClick}
      handleShiftSelect={handleShiftSelection}
    >
      <div className="w-full relative flex items-center space-x-3 px-6 py-0 focus-within:ring-2 focus-within:ring-inset focus-within:ring-gray-500 hover:bg-gray-50">
        <Avatar
          Icon={BuildingOfficeIcon}
          name={AllowedContactType.company !== contact.type ? name : ""}
          src={user?.photoURL}
          iconClassName="h-7 w-7"
          className={clsx("h-10 w-10 min-w-10 min-h-10", {
            "text-gray-600": contact.type === AllowedContactType.company,
          })}
        />
        <div className="min-w-0 flex-1">
          <p className="text-base font-medium text-gray-900 truncate">{name}</p>
          {contact.type === "person" && (
            <p className="text-sm font-medium text-gray-500 truncate"></p>
          )}{" "}
          {/* prepare to render company name here */}
        </div>
      </div>
    </HoverListSelector>
  );
};
