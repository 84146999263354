/*
GitHub Copilot: 
The code you've selected is a common pattern used in JavaScript and TypeScript to delay the execution 
of a function until the current call stack has cleared. This is done using the `setTimeout` function 
with a delay of `0` milliseconds.In this specific case, the function being delayed is `e.target.focus()`. 
This is a method that sets focus on a specific DOM element, `e.target`, where `e` is the event object passed 
to an event handler function.The reason for using `setTimeout` here is to ensure that the `focus()` method is 
called after any other pending JavaScript operations have completed. This can be necessary in certain situations where, 
for example, the element you want to focus is being rendered or modified by another JavaScript operation. 
By delaying the `focus()` call, you ensure that the element is in the correct state when the method is called.
This pattern is commonly used in React and other JavaScript libraries to handle timing issues related to the 
rendering and modification of DOM elements.
*/

import Fuse from "fuse.js";
import { Option } from "@amenda-types";
import isNil from "lodash/isNil";

export const handleWheelChange = (e: any) => {
  // Prevent the input value change
  e.target.blur();
  // Prevent the page/container scrolling
  e.stopPropagation();
  // Refocus immediately
  setTimeout(() => {
    e.target.focus();
  }, 0);
};

export const getMailOrPhoneRef = (type: "email" | "phone", value: string) => {
  if (type === "email") {
    return `mailto:${value}`;
  }

  return `tel:${value}`;
};

export interface ItemTree {
  name: string;
  children?: string[];
  isFolder?: boolean;
}

export const getOptionsFromGroup = (group: Option[] = []) => {
  const options: Option[] = [];

  group.forEach((g) => {
    options.push(...(g.children ?? []));
  });

  return options;
};

export const resolveValuesToArray = (values?: string[] | string) => {
  return Array.isArray(values) ? values : isNil(values) ? [] : [values];
};

export const getResolvedValues = (
  options: Option[],
  values?: string[] | string,
) => {
  const selectedValues = resolveValuesToArray(values);
  let resolvedValues: Option[] = [];

  selectedValues.forEach((value) => {
    const resolvedValue = options.find((option) => option?.value === value);
    if (resolvedValue) {
      resolvedValues.push(resolvedValue);
    }
  });

  return resolvedValues;
};

export type OptionAndParent = {
  parent?: string;
  index?: number;
  option: Option;
};

export const getOptionsParentMap = (options: Option[]) => {
  const optionsParentMap: Record<string, OptionAndParent> = {};
  const stack: any[] = [
    ...options.map((o, i) => ({
      ...o,
      index: i,
      parent: undefined,
    })),
  ];

  while (stack.length > 0) {
    const { parent, index, ...option } = stack.pop()!;
    optionsParentMap[option.value] = {
      option,
      parent,
      index,
    };

    if (option.children) {
      stack.push(
        ...option.children.map((child: any, i: number) => ({
          parent: option.value,
          index: i,
          ...child,
        })),
      );
    }
  }

  return optionsParentMap;
};

export const getFlatOptions = (options: Option[]) => {
  const flatOptions: Option[] = [];
  const stack: Option[] = [...options];

  while (stack.length > 0) {
    const { children, ...option } = stack.pop()!;
    flatOptions.push(option);

    if (children) {
      stack.push(...children);
    }
  }

  return flatOptions;
};

export const getItemTree = (options: Option[]) => {
  const items: Record<string, ItemTree> = {};

  const stack: Option[] = [...options];

  while (stack.length > 0) {
    const option = stack.pop()!;

    items[option.value] = {
      name: option.label,
      children: option.children?.map((child) => child.value),
      isFolder: Boolean(option.children && option.children.length > 0),
    };

    if (option.children) {
      stack.push(...option.children);
    }
  }

  items["root"] = {
    name: "Root",
    children: options.map((option) => option.value),
    isFolder: true,
  };

  return items;
};

export const getItemChildren = (
  itemId: string,
  items: Record<string, ItemTree>,
) => {
  const children: string[] = [];
  const stack: string[] = [itemId];

  while (stack.length > 0) {
    const currentId = stack.pop()!;
    const currentItem = items[currentId];

    if (currentItem && currentItem.children) {
      currentItem.children.forEach((childId) => {
        children.push(childId);
        stack.push(childId);
      });
    }
  }

  return children;
};

export const createParentMap = (
  items: Record<string, ItemTree>,
): Record<string, string> => {
  const parentMap: Record<string, string> = {};
  Object.entries(items).forEach(([parentId, item]) => {
    item.children?.forEach((childId) => {
      parentMap[childId] = parentId;
    });
  });
  return parentMap;
};

export const getItemAncestors = (
  itemId: string,
  parentMap: Record<string, string>,
) => {
  const ancestors: string[] = [];
  let currentId = itemId;

  while (true) {
    const parentId = parentMap[currentId];
    if (!parentId || parentId === "root") break;

    ancestors.push(parentId);
    currentId = parentId;
    if (currentId === "root") break;
  }

  return ancestors.reverse();
};

export const getSearchFlatOptions = (
  flatOptions: Option[],
  searchTerm: string,
) => {
  const fuse = new Fuse(flatOptions, {
    includeScore: true,
    keys: ["label", "value"],
    threshold: 0.3,
  });
  const results = fuse.search(searchTerm);
  return results.map((res) => res.item);
};

export const getExpandedOptions = (
  options: any[],
  isOpen: Record<string, boolean>,
) => {
  const expandedOptions: any[] = [];
  const stack: [any, number][] = options.map((option) => [option, 0]);

  while (stack.length > 0) {
    const [option, level] = stack.pop()!;
    expandedOptions.push({ ...option, level });

    if (isOpen[option.value] && option.children) {
      // Add children to the stack in reverse order to maintain original order when popped
      for (let i = option.children.length - 1; i >= 0; i--) {
        stack.push([option.children[i], level + 1]);
      }
    }
  }

  return expandedOptions;
};

export const getSearchCountries = <T>(
  countriesInfo: T[],
  searchTerm: string,
) => {
  const fuse = new Fuse(countriesInfo, {
    includeScore: true,
    keys: ["label", "formattedCode"],
    threshold: 0.5,
  });
  const results = fuse.search(searchTerm);
  return results.map((res) => res.item);
};
