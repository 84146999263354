import { ChevronRightIcon, XIcon } from "lucide-react";
import { FC, Fragment, useMemo, useRef, useState } from "react";
import {
  ItemTree,
  createParentMap,
  getFlatOptions,
  getItemAncestors,
  getItemChildren,
  getItemTree,
  getSearchFlatOptions,
} from "./common";
import {
  expandAllFeature,
  selectionFeature,
  syncDataLoaderFeature,
} from "@headless-tree/core";
import { inputFieldTheme, tagTheme } from "@amenda-styles/theme";

import { Option } from "@amenda-types";
import { ReactSelectProps } from "./ReactSelect";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import isNil from "lodash/isNil";
import { useTree } from "@headless-tree/react";

interface Props extends ReactSelectProps {
  value?: string[];
  onChange: (value: any) => void;
}

const getIsSelected = (itemId: string, values: string[]) => {
  const isSelected = values.some((v) => {
    return v === itemId;
  });

  return isSelected;
};

const allChildrenAreSelected = ({
  itemId,
  items,
  values,
}: {
  itemId: string;
  items: Record<string, ItemTree>;
  values: string[];
}) => {
  const children = getItemChildren(itemId, items);
  return children.every((childId) => values.some((v) => v === childId));
};

const getIsParentSelected = ({
  itemId,
  parentMap,
  values,
}: {
  itemId: string;
  parentMap: Record<string, string>;
  values: string[];
}) => {
  const parentValues = getItemAncestors(itemId, parentMap);

  const isParentSelected = values.some((v) => {
    return parentValues.includes(v);
  });

  return isParentSelected;
};

const getSearchResults = ({
  inputValue,
  isFolder,
  searchResults,
  itemId,
  items,
}: {
  inputValue: string;
  isFolder: boolean;
  searchResults: Option[];
  itemId: string;
  items: Record<string, ItemTree>;
}) => {
  const isSearchEmpty = inputValue.length <= 0;
  const values = searchResults.map((r) => r.value);
  let matchesSearch = values.includes(itemId);
  let hasChildrenMatching = false;

  if (isFolder && !matchesSearch) {
    const children = getItemChildren(itemId, items);
    matchesSearch = values.some((v) => children.includes(v));
    hasChildrenMatching = matchesSearch;
  }

  return {
    isSearchEmpty,
    matchesSearch,
    hasChildrenMatching,
  };
};

interface TagBreadCrumbProps {
  itemId: string;
  items: Record<string, ItemTree>;
  parentMap: Record<string, string>;
  className?: string;
}

export const ReactTreeSelectBreadCrumb: FC<TagBreadCrumbProps> = ({
  itemId,
  items,
  parentMap,
  className,
}) => {
  const parentValues = getItemAncestors(itemId, parentMap);

  return (
    <div className={clsx("flex items-center space-x-1", className)}>
      {parentValues.map((value) => (
        <Fragment key={value}>
          <span className="leading-none">{items[value]?.name}</span>
          <ChevronRightIcon className="h-3 w-3" />
        </Fragment>
      ))}
      <span className="leading-none">{items[itemId]?.name}</span>
    </div>
  );
};

export const ReactTreeSelect: FC<Props> = ({
  options,
  value,
  placeholder,
  hasMenuOverflow,
  canSelectParents = false,
  onChange,
}) => {
  const items = useMemo(() => getItemTree(options), [options]);
  const flatOptions = useMemo(() => getFlatOptions(options), [options]);
  const { inputCss, multiSelectWrapperCss } = inputFieldTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const wrapperRef = useRef<HTMLInputElement>(null);

  const features: any[] = [
    syncDataLoaderFeature,
    selectionFeature,
    expandAllFeature,
  ];

  const tree = useTree<ItemTree>({
    rootItemId: "root",
    getItemName: (o) => o.getItemData().name,
    isItemFolder: (o) => o.getItemData().isFolder ?? false,
    dataLoader: {
      getItem: (value) => items[value],
      getChildren: (value) => items[value]?.children ?? [],
    },
    features,
  });

  const parentMap = createParentMap(items);
  const values = Array.isArray(value) ? value : !isNil(value) ? [value] : [];
  const searchResults = getSearchFlatOptions(flatOptions, inputValue);
  const wrapperWidth = wrapperRef?.current?.getBoundingClientRect().width;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length === 1 && tree.expandAll) {
      tree.expandAll();
    }
    setIsOpen(true);
    setInputValue(value);
  };

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    const { expandedItems } = tree.getState();
    if (!isEmpty(expandedItems) && tree.collapseAll) {
      tree.collapseAll();
    }
    setInputValue("");
    setIsOpen(false);
  };

  const handleRemoveOptions = (itemId: string, isFolder: boolean) => {
    let updatedValues = values.map((v) => ({ value: v }));

    if (isFolder) {
      const children = getItemChildren(itemId, items);
      updatedValues = updatedValues.filter((v) => v.value !== itemId);
      updatedValues = updatedValues.filter((v) => !children.includes(v.value));
      onChange(updatedValues);
    } else {
      updatedValues = updatedValues.filter((v) => v.value !== itemId);
      onChange(updatedValues);
    }
  };

  const handleSelectOption = (itemId: string, isFolder: boolean) => {
    let updatedValues = values.map((v) => ({ value: v }));

    if (isFolder) {
      const children = getItemChildren(itemId, items);
      updatedValues = updatedValues.filter((v) => !children.includes(v.value));
      onChange([{ value: itemId }, ...updatedValues]);
    } else {
      onChange([{ value: itemId }, ...updatedValues]);
    }
  };

  const handleClick =
    ({
      itemId,
      isFolder,
      collapse,
    }: {
      itemId: string;
      isFolder: boolean;
      collapse?: () => void;
    }) =>
    () => {
      const { expandedItems } = tree.getState();
      if (isFolder && collapse && expandedItems.includes(itemId)) {
        collapse();
      }
      setInputValue("");
      handleSelectOption(itemId, isFolder);
    };

  const handleRemoveTag = (itemId: string) => {
    const item = items[itemId];
    handleRemoveOptions(itemId, !isEmpty(item.children));
  };

  return (
    <div
      ref={wrapperRef}
      className="w-full"
      onMouseLeave={handleClose}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div
        className={multiSelectWrapperCss({
          size: "md",
        })}
      >
        <div
          className={inputCss({
            class: "flex justify-between items-stretch w-full px-2 py-1",
          })}
        >
          <div className="flex flex-wrap items-center gap-1 grow">
            {values.map((value) => (
              <div
                key={value}
                className={tagTheme({
                  variant: "default",
                  class: "justify-center h-max bg-gray-200 text-xs py-0 pr-0",
                })}
              >
                <ReactTreeSelectBreadCrumb
                  itemId={value}
                  items={items}
                  parentMap={parentMap}
                  className="pr-2"
                />
                <button
                  className="text-gray-600 hover:text-red-700 hover:bg-red-200 h-full p-1.5"
                  onClick={() => handleRemoveTag(value)}
                >
                  <XIcon className="w-3 h-3" />
                </button>
              </div>
            ))}
            <input
              type="text"
              value={inputValue}
              placeholder={placeholder}
              onClick={handleOpen}
              className="focus:outline-none border-0 focus:ring-0 flex-grow px-2 py-0 h-5"
              ref={tree.registerSearchInputElement}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex items-center gap-1">
            <div className="border-l border-gray-300 h-[80%]" />
            <ChevronRightIcon
              className={clsx("w-5 h-5 text-gray-500", { "rotate-90": isOpen })}
            />
          </div>
        </div>
      </div>
      <Transition show={isOpen}>
        <div
          ref={tree.registerElement}
          className={clsx(
            "bg-white shadow-md font-apercu max-h-96 overflow-y-auto overscroll-y-contain overflow-x-hidden transition duration-100 ease-in data-[closed]:opacity-0",
            {
              "mt-2": !Boolean(hasMenuOverflow),
              "fixed z-20": Boolean(hasMenuOverflow),
            },
          )}
          style={
            hasMenuOverflow
              ? {
                  width: wrapperWidth,
                }
              : {}
          }
        >
          {tree.getItems().map((item) => {
            const isHighlighted = item.isFocused() || item.isSelected();
            const isFolder = item.isFolder();
            const itemId = item.getId();
            const { isSearchEmpty, matchesSearch, hasChildrenMatching } =
              getSearchResults({
                inputValue,
                isFolder,
                searchResults,
                itemId,
                items,
              });

            if (!isSearchEmpty && !matchesSearch) {
              return null;
            }
            if (
              getIsSelected(itemId, values) ||
              (isFolder &&
                getIsParentSelected({
                  itemId,
                  parentMap,
                  values,
                }))
            ) {
              return null;
            }
            return (
              <div
                key={itemId}
                className={clsx(
                  "flex items-center px-2 text-sm space-x-2 w-full hover:bg-gray-900 hover:text-white",
                  {
                    "bg-gray-100 text-gray-900": isHighlighted,
                  },
                )}
              >
                <div
                  style={{
                    paddingLeft: `${item.getItemMeta().level * 20}px`,
                  }}
                />
                {isFolder &&
                (!isSearchEmpty
                  ? hasChildrenMatching
                  : !allChildrenAreSelected({ itemId, items, values })) ? (
                  <button {...item.getProps()} ref={item.registerElement}>
                    <ChevronRightIcon
                      className={clsx("w-4 h-4", {
                        "rotate-90": item.isExpanded(),
                      })}
                    />
                  </button>
                ) : (
                  <div className="h-4 w-4" />
                )}
                <button
                  disabled={isFolder && !canSelectParents}
                  onClick={handleClick({
                    itemId,
                    isFolder,
                    collapse: item.collapseAll ?? item.collapse,
                  })}
                  className="leading-none grow text-left py-3"
                >
                  {item.getItemName()}
                </button>
              </div>
            );
          })}
        </div>
      </Transition>
    </div>
  );
};
