import "react-lazy-load-image-component/src/effects/blur.css";

import { AvailableImageVariants, RoundedVariants } from "@amenda-types";
import { FC, ReactNode, useState } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import { LoaderWrapper } from "./LoaderWrapper";
import clsx from "clsx";
import { getProcessedUrl } from "./common";

interface Props {
  id?: string;
  url: string;
  isFullWidth?: boolean;
  variant?: string;
  size?: string;
  enableHighlighting?: boolean;
  magicSize?: string;
  rounded?: RoundedVariants;
  lazyLoad?: boolean;
  background?: string;
  isMasked?: boolean;
  ignoreMagicLinks?: boolean;
  className?: string;
  isLoading?: boolean;
  showLoader?: boolean;
  loaderClassName?: string;
  children?: (imageDimensions?: any) => ReactNode;
}

export const Image: FC<Props> = ({
  id,
  url,
  children,
  className,
  isLoading,
  loaderClassName = "min-h-96 h-full",
  isFullWidth = false,
  size = "pb-10/12",
  rounded = RoundedVariants.none,
  magicSize = "default",
  variant = AvailableImageVariants.cover,
  enableHighlighting = true,
  lazyLoad = true,
  background = "bg-white",
  isMasked = false,
  showLoader = false,
  ignoreMagicLinks = false,
}) => {
  const [imageDimensions, setImageDimensions] = useState<any>();
  const [loading, setLoading] = useState(true);

  const processedUrl = getProcessedUrl({
    url,
    magicSize,
    ignoreMagicLinks,
    showLoader,
  });

  return (
    <div
      className={clsx(
        "relative overflow-hidden select-none",
        size,
        rounded,
        background,
        className,
        {
          "group-hover:opacity-75 ease-in-out duration-300": enableHighlighting,
          "bg-transparent": variant !== AvailableImageVariants.contain || !url,
        },
      )}
    >
      {showLoader && Boolean(isLoading || loading) && (
        <LoaderWrapper
          variant="default"
          className={clsx(
            "absolute top-0 w-full z-10",
            background,
            loaderClassName,
          )}
        />
      )}
      {lazyLoad ? (
        <LazyLoadImage
          id={id}
          className={clsx(
            "h-full w-full absolute ease-in-out duration-300",
            variant,
            {
              "bg-white opacity-50": isMasked,
              "w-full": isFullWidth,
              "w-auto": !isFullWidth,
            },
          )}
          wrapperClassName="amenda-lazy-load-wrapper"
          effect="opacity"
          placeholderSrc={processedUrl}
          src={processedUrl}
          visibleByDefault={processedUrl}
          alt=""
          onLoad={(e: any) => {
            setLoading(false);
            setImageDimensions({
              width: e.target.width,
              height: e.target.height,
            });
          }}
        />
      ) : (
        <img
          id={id}
          className={clsx(
            "h-full w-full absolute ease-in-out duration-300",
            variant,
            {
              "bg-gray-300 opacity-50": isMasked,
            },
          )}
          src={processedUrl}
          onLoad={() => {
            setLoading(false);
          }}
          alt=""
        />
      )}
      {children?.(imageDimensions)}
    </div>
  );
};
