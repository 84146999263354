import { Accordion } from "@amenda-components/Shared";
import { FC } from "react";
import { GridWrapper } from "./GridWrapper";
import { LayoutProps } from "./common";
import { useTranslation } from "react-i18next";

export const ReadOnlyTwoColumnsFullWidth: FC<LayoutProps> = ({
  config,
  children,
  globalProps,
}) => {
  const { t } = useTranslation();
  const { label, description } = config?.properties ?? {};

  if (Boolean(globalProps?.withAccordion)) {
    return (
      <div id={config?.id} className="w-full">
        <Accordion
          className="pb-4"
          panelClassName="p-0"
          titleClassName="amenda-form-heading"
          title={t(label ?? "")}
          defaultOpen={true}
        >
          <div
            data-testid="readOnlyTwoColumnsFullWidthLayout"
            className="space-y-6"
          >
            <div className="amenda-form-layout border-none">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  {description && (
                    <p className="mt-1 text-sm text-gray-600">
                      {t(description)}
                    </p>
                  )}
                </div>
                <div className="mt-5 md:mt-0 md:col-span-2">
                  <GridWrapper>{children}</GridWrapper>
                </div>
              </div>
            </div>
          </div>
        </Accordion>
      </div>
    );
  }
  return (
    <div
      id={config?.id}
      data-testid="readOnlyTwoColumnsFullWidthLayout"
      className="space-y-6"
    >
      <div className="amenda-form-layout">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            {label && <h3 className="amenda-form-heading">{t(label)}</h3>}
            {description && (
              <p className="mt-1 text-sm text-gray-600">{t(description)}</p>
            )}
          </div>
          <div className="mt-5 md:mt-0 md:col-span-2">
            <GridWrapper>{children}</GridWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};
