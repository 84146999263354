import { gql } from "urql";

export const MATERIAL_FRAGMENT = gql`
  fragment MaterialFragment on Material {
    id: _id
    name
    formValues
    isDeleted
    ownerId
    share {
      type
      users {
        id
        role
      }
    }
  }
`;

export const MATERIAL_SUBSCRIPTION_FRAGMENT = gql`
  fragment MaterialSubsFragment on MaterialSubscription {
    id: _id
    isDeleted
  }
`;
