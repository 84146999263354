import { AddComponentButton, SortItem } from "./DragAndDropWrapper";
import { CheckIcon, GripIcon, PencilIcon, TrashIcon } from "lucide-react";
import {
  Control,
  UseFieldArrayRemove,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import { FC, ReactNode, useState } from "react";
import { IconButton, IconButtonBase } from "@amenda-components/App";
import {
  devConsole,
  getNestedFormValidation,
  isChildrenEmpty,
  sanitizeNestedData,
} from "@amenda-utils";
import { useAppStore, useUsersStore } from "@amenda-domains/mutations";

import { GridWrapper } from "./GridWrapper";
import { LayoutProps } from "./common";
import { NestedFormContext } from "@amenda-components/PageBuilder";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

type LayoutWrapperProps = LayoutProps & {
  showBorder?: boolean;
  showActions?: boolean;
  index?: number;
  isEditing?: boolean;
  remove?: UseFieldArrayRemove;
  toggleIsEditing?: () => void;
};

const LayoutWrapper: FC<LayoutWrapperProps> = ({
  index,
  isFormBuilder,
  config,
  children,
  showBorder,
  isEditing = false,
  showActions = false,
  remove,
  toggleIsEditing,
}) => {
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );

  const handleEdit = () => {
    updateFormBuilderState("openEditComponentModal", true);
    updateFormBuilderState("selectedFormComponent", {
      config,
    });
  };

  const handleDelete = () => {
    remove?.(index);
  };

  return (
    <SortItem
      className={clsx("col-span-6 group/nestedFormLayout", {
        "bg-white border min-h-16 shrink-0 px-6 py-4 group-focus-within/nestedFormLayout:border-gray-700":
          !isFormBuilder,
      })}
      config={config}
      showBorder={showBorder}
      isFormBuilder={isFormBuilder}
    >
      {isFormBuilder && (
        <div
          className={clsx("relative flex items-center space-x-2 pb-2", {
            "invisible group-hover/nestedFormLayout:visible": !showBorder,
          })}
        >
          <IconButtonBase
            size="sm"
            className="cursor-grab px-0.5 py-1"
            variant="clean"
          >
            <GripIcon className="w-5 h-5 stroke-[2]" />
          </IconButtonBase>
          <IconButtonBase
            size="sm"
            className="p-1"
            variant="clean"
            onClick={handleEdit}
          >
            <PencilIcon className="w-5 h-5" />
          </IconButtonBase>
          <AddComponentButton
            className="absolute top-0 right-0 w-full flex justify-end -mt-7 !-mr-2"
            isFormBuilder={isFormBuilder}
            config={config}
          />
        </div>
      )}
      {!isFormBuilder && showActions && (
        <div className="flex space-x-1 items-center justify-end">
          <IconButton
            label="Delete item"
            iconSize={1}
            className="flex items-center justify-center text-gray-900 bg-white hover:bg-gray-900 hover:text-white"
            Icon={TrashIcon}
            onClick={handleDelete}
          />
          <IconButton
            label={isEditing ? "Save and Close" : "Edit item"}
            Icon={isEditing ? CheckIcon : PencilIcon}
            className="flex items-center justify-center text-gray-900 bg-white hover:bg-gray-900 hover:text-white"
            iconSize={1}
            onClick={toggleIsEditing}
          />
        </div>
      )}
      <GridWrapper isFormBuilder={isFormBuilder} config={config}>
        {children}
      </GridWrapper>
    </SortItem>
  );
};

interface NestedFormProps {
  control: Control<any>;
  readOnly: boolean;
}

type EditableNestedFormProps = LayoutProps & NestedFormProps;
type NewNestedFormProps = LayoutProps & {
  append: (data: any) => void;
};

const NewNestedForm: FC<NewNestedFormProps> = ({
  config,
  children,
  append,
  ...rest
}) => {
  const { t } = useTranslation();
  const { control, handleSubmit, reset } = useForm<any>({
    values: {},
    resolver: yupResolver(getNestedFormValidation(config)),
  });
  const currentUser = useUsersStore((state) => state.currentUser);

  const onError = (errors: any, e: any) => {
    devConsole?.warn("amenda:project form error gat", errors);
  };

  const onSubmit = (data: any) => {
    const sanitizedData = sanitizeNestedData(data);

    append({ ...sanitizedData, ownerId: currentUser?.id });
    reset();
  };

  return (
    <NestedFormContext.Provider value={{ control }}>
      <LayoutWrapper {...rest}>
        {children}
        <div className="col-span-6 flex justify-end">
          <div>
            <button
              type="button"
              className="amenda-button"
              onClick={handleSubmit(onSubmit, onError)}
            >
              {t("Add entry")}
            </button>
          </div>
        </div>
      </LayoutWrapper>
    </NestedFormContext.Provider>
  );
};

const getNestedId = (index: number, config?: any) => {
  if (config?.id) {
    return `${config.id}.${index}`;
  }
  return config?.id;
};

type ToggleEditNestedFormProps = NestedFormProps & {
  nestedId: string;
  children: ReactNode;
};

const ToggleEditNestedForm: FC<ToggleEditNestedFormProps> = ({
  control,
  children,
  readOnly,
  nestedId,
}) => {
  const watch = useWatch({
    control,
    name: nestedId,
  });

  return (
    <div className="w-full">
      <NestedFormContext.Provider
        value={{
          readOnly,
          data: watch,
          id: !readOnly ? nestedId : undefined,
        }}
      >
        {children}
      </NestedFormContext.Provider>
    </div>
  );
};

const EditableNestedForm: FC<EditableNestedFormProps> = ({
  control,
  children,
  config,
  ...rest
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: config?.id ?? "",
  });
  const [editFieldId, setEditFieldId] = useState("");

  const toggleIsEditing = (fieldId: string) => {
    setEditFieldId((prev) => {
      if (prev === fieldId) {
        return "";
      }
      return fieldId;
    });
  };

  return (
    <div className="w-full space-y-3">
      <NewNestedForm config={config} append={append} {...rest}>
        {children}
      </NewNestedForm>
      {fields.map((field, i) => (
        <ToggleEditNestedForm
          key={field.id}
          control={control}
          readOnly={field.id !== editFieldId}
          nestedId={getNestedId(i, config)}
        >
          <LayoutWrapper
            index={i}
            config={config}
            showActions={true}
            isEditing={field.id === editFieldId}
            remove={remove}
            toggleIsEditing={() => toggleIsEditing(field.id)}
            {...rest}
          >
            {children}
          </LayoutWrapper>
        </ToggleEditNestedForm>
      ))}
    </div>
  );
};

type Props = LayoutProps & Partial<NestedFormProps>;

export const NestedFormLayout: FC<Props> = ({
  config,
  children,
  globalProps,
  isFormBuilder,
  readOnly = false,
}) => {
  const control = globalProps?.control;

  if (isFormBuilder) {
    return (
      <NestedFormContext.Provider value={{ id: config?.id }}>
        <LayoutWrapper
          isFormBuilder={isFormBuilder}
          config={config}
          showBorder={isChildrenEmpty(children)}
        >
          {children}
        </LayoutWrapper>
      </NestedFormContext.Provider>
    );
  } else if (!readOnly && control && config?.id) {
    return (
      <EditableNestedForm
        control={control}
        isFormBuilder={isFormBuilder}
        config={config}
        readOnly={readOnly}
      >
        {children}
      </EditableNestedForm>
    );
  }

  const values: undefined | any[] = globalProps?.data?.[config?.id ?? ""];
  return (
    <div className="w-full space-y-2">
      {values?.map((data, i) => (
        <NestedFormContext.Provider
          key={i}
          value={{
            data,
          }}
        >
          <LayoutWrapper
            isFormBuilder={isFormBuilder}
            config={config}
            showBorder={isChildrenEmpty(children)}
          >
            {children}
          </LayoutWrapper>
        </NestedFormContext.Provider>
      ))}
    </div>
  );
};
