import { FC } from "react";
import { FileError } from "react-dropzone";
import { FormComponentProps } from "./form";

export enum AvailableImageVariants {
  fill = "object-fill",
  none = "object-none",
  contain = "object-contain",
  cover = "object-cover",
  scaleDown = "object-scale-down",
}

export enum AllowedTimelineTypes {
  Project = "projects",
  Contact = "contacts",
  Material = "materials",
  Media = "attachments",
}
export enum ImageDescriptorType {
  face = "face",
}

export enum TimelineActivityTypes {
  Email = "email",
  PhoneCall = "phoneCall",
  Note = "note",
  Meeting = "meeting",
  Comment = "comment",
  Other = "other",
}

export interface NavigationProps {
  path: string;
  label: string;
  matchPaths?: string[];
}

export enum LogicalOperators {
  AND = `$all`,
  OR = `$in`,
  NOT = `$nin`,
  RESET = "reset",
}

export enum AvailableNotificationTypes {
  Success = "success",
  Error = "error",
  Warning = "warning",
  General = "general",
}

export enum AuthFormType {
  Login = "Login",
  ResetPassword = "ResetPassword",
  CheckYourEmail = "CheckYourEmail",
}

export enum ProjectView {
  Gallery = "gallery",
  Map = "map",
  Table = "table",
}

export interface TabItemLink {
  path: string;
  label: string;
  disabled?: boolean;
}

export interface TabItem {
  label: string;
  value: string;
  disabled?: boolean;
}

export interface CustomTabComponentProps {
  selectedTab: string;
  tabs: TabItemLink[];
  onPathChange: (path: string) => void;
}

export enum ComponentValidationType {
  Any = "any",
  String = "string",
  Email = "email",
  Number = "number",
  Boolean = "boolean",
  Array = "array",
  DateRange = "dateRange",
}

export type ValidationProperties = {
  [key: string]: any;
};

export interface SingleValidation {
  id: string;
  type: ComponentValidationType;
  default?: string | boolean | number | null | Array<any>;
  properties: ValidationProperties | ValidationProperties[];
}

export interface Option {
  label: string;
  value: string;
  count?: number;
  unavailable?: boolean;
  description?: string;
  children?: Option[];
}

export interface PropertyProps {
  content?: string;
  id: string;
  type?: "function";
}

export interface TableColumnProps {
  accessor?: string;
  columnProps?: any;
  component?: string;
  componentProperties?: any;
  defaultProperties?: any;
  id: string;
  headerLabel?: string;
}

export interface ComponentHeaderProperties {
  order: number;
  component: string;
  layout: string;
  properties?: any;
}

export interface PageComponentProps {
  component?: string;
  components?: any[];
  description?: string;
  title?: string;
  headerProperties?: ComponentHeaderProperties;
  id: string;
  isBulkEditable?: boolean;
  isSearchable?: boolean;
  isGroupable?: boolean;
  isContact?: boolean;
  isAppSpecific?: boolean;
  formId?: string;
  versionGroupName?: string;
  layout: string;
  order: number;
  parentId: null | string;
  display?: ComponentDisplayCondition[];
  properties?: Record<string, any>;
  validation?: SingleValidation;
}
export interface NestedPageComponentProps extends PageComponentProps {
  components?: NestedPageComponentProps[];
}

export interface ComponentTreeRendererProps {
  customComponents?: Record<string, FC<FormComponentProps>>;
  customFunctions?: Record<string, (v?: any) => void>;
  customLayoutComponents?: Record<string, FC<any>>;
  config: NestedPageComponentProps;
  globalProps?: Record<string, any>;
  readOnly?: boolean;
  isFormBuilder?: boolean;
  isSimilaritySearch?: boolean;
}

export const AllowedCategories = {
  Users: "users",
  Contacts: "contacts",
  Company: "company",
  Projects: "projects",
  Portfolio: "portfolios",
};

export enum AllowedContactType {
  person = "person",
  company = "company",
  office = "office",
}

export enum AllowedUserUpdateType {
  registration = "registration",
}

export enum AllowedAttachmentType {
  pdf = "pdf",
  image = "image",
  svg = "svg",
}

export interface DragAndDropItemProps {
  value: string;
  label: string;
  show: boolean;
}

export enum GalleryView {
  Grid = "grid",
  Table = "table",
}

export const SupportedPaperSizes: Record<string, string> = {
  auto: "w-auto",
  a3: "amenda-a3",
  a4: "amenda-a4",
  a5: "amenda-a5",
};

export enum RoundedVariants {
  left = "rounded-l-xl",
  right = "rounded-r-xl",
  full = "rounded-xl",
  none = "",
}

export enum PdfTypes {
  A3 = "a3",
  A4 = "a4",
}

export const VALID_IMAGE_TYPES = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "image/webp",
  "image/tiff",
];
export const VALID_PDF_FORMATS = ["application/pdf"];
export const VALID_FILE_FORMATS = [...VALID_IMAGE_TYPES, ...VALID_PDF_FORMATS];

export interface TableTitleProps {
  onPaginationChange?: (page: number) => void;
  pageIndex: number;
  pageCount: number;
  title?: string;
  withPagination?: boolean;
}

export interface TableProps extends Omit<TableTitleProps, "pageIndex"> {
  data: any[];
  columns: any[];
  currentPage?: number;
  loading?: boolean;
  noValuesMessage?: string;
  tableRowProps?: (row: any) => {};
}

export enum AppRoutes {
  Root = "/",
  Attachments = "/medien",
  AttachmentsCollection = "/medien/sammlungen",
  Projects = "/projekte",
  ProjectsCollection = "/projekte/sammlungen",
  ProjectView = "/projekte/:projectId",
  ProjectCreate = "/projekte/neu/allgemein",
  ProjectEdit = "/bearbeiten",
  Contacts = "/kontaktverzeichnis",
  Colleagues = "/bueroverzeichnis",
  Logout = "/logout",
  Materials = "/einheitspreise",
  FormBuilder = "/admin/form-builder",
  ConstructionDetails = "/planungsdetails",
  ConstructionDetailsCollection = "/planungsdetails/sammlungen",
  Settings = "/einstellungen",
  Admin = "/admin",
  ConstructionDetailsNew = "/planungsdetails/neu",
  ConstructionDetailsView = "/planungsdetails/:constructionDetailId",
  ConstructionDetailsEdit = "/planungsdetails/:constructionDetailId/bearbeiten",
}

export enum HoverVariants {
  Default = "default",
  Checkbox = "checkbox",
  RadioButton = "radioButton",
  NoBorder = "no-border",
}

export type FileUploaderDropZoneValidator = FileError | FileError[] | null;

export enum AggregationOperations {
  SUM = "Sum",
  AVERAGE = "Average",
  MINIMUM = "Min",
  MAXIMUM = "Max",
  FORMULAE = "Formulae",
}

export enum ChartVariations {
  Doughnut = "Doughnut",
  Bar = "Bar",
  Line = "Line",
  Pie = "Pie",
}

export enum AvailableWidgetTypes {
  Formulae = "formulae",
  Chart = "chart",
}

export enum AvailablePermissions {
  Restricted = "none",
  Read = "read",
  Edit = "read-write",
}

export const permissionHeaders = [
  {
    label: "None",
    value: AvailablePermissions.Restricted,
  },
  {
    label: "Read",
    value: AvailablePermissions.Read,
  },
  {
    label: "Edit",
    value: AvailablePermissions.Edit,
  },
];

export interface SystemRole {
  id: string;
  name: string;
  description: string;
  permissions: Record<string, any>;
  usersIds: string[];
  isDefaultRole: boolean;
}

export interface Pagination {
  next?: string;
  previous?: string;
  hasNext?: boolean;
  hasPrevious?: boolean;
  docsCount?: number;
  limit?: number;
}

export type PaginationQueryProps = Pick<
  Pagination,
  "next" | "previous" | "limit"
>;
export enum availableLayouts {
  default = "default_layout",
  fullColumn = "full_column",
  halfColumn = "half_column",
  oneThirdColumn = "one_third_column",
  twoThirdColumn = "two_third_column",
  twoColumnsFullWidth = "two_columns_full_width",
  fullColumnGrid = "full_column_grid",
  nestedForm = "nested_form_layout",
}

export enum Conditions {
  IsEqual = "isEqual",
  Includes = "includes",
  GreaterThan = "greaterThan",
  LessThan = "lessThan",
}

export interface ComponentDisplayCondition {
  and?: boolean;
  when: string;
  condition: Conditions;
  value: string | number | boolean | string[];
}

export enum MediaDropzoneUploadStatus {
  Uploading = "uploading",
  Error = "error",
  Success = "success",
}

export enum AllowedCollectionType {
  Attachments = "attachments",
  Projects = "projects",
  Users = "users",
  Contacts = "contacts",
  Widgets = "widgets",
  Materials = "materials",
  ConstructionDetails = "attachments_construction_details",
}

export enum GeneralPermissionTypes {
  Create = "create",
  Delete = "delete",
  Edit = "edit",
  Share = "share",
  Move = "move",
  Download = "download",
  FullAccess = "fullAccess",
  Activity = "activity",
  CanDetectFaces = "canDetectFaces",
  Archive = "archive",
}

export enum ReactTableKeys {
  Users = "users",
  Roles = "roles",
  Projects = "projects",
  Gallery = "gallery",
  RegionalFactors = "regionalFactors",
  ContactsFullScreenTable = "contactsFullScreenTable",
  ConstructionPriceIndices = "constructionPriceIndices",
}

export enum SimilaritySearchConfigColumns {
  Weight = "weight",
  Match = "matchType",
  IsDefault = "isDefault",
}

export enum SimilaritySearchMatchTypes {
  Exact = "exact",
  Similar = "similar",
  ContainsAll = "containsAll",
}

export const similaritySearchConfigColumns = [
  {
    label: "Weight",
    id: SimilaritySearchConfigColumns.Weight,
  },
  {
    label: "Match",
    id: SimilaritySearchConfigColumns.Match,
  },
  {
    label: "Default",
    id: SimilaritySearchConfigColumns.IsDefault,
    className: "w-[10rem]",
  },
];
