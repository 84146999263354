import {
  Button,
  IconButton,
  ModalBase,
  ModalBaseTitle,
} from "@amenda-components/App";
import { FC, KeyboardEvent, ReactNode, useEffect } from "react";

import { XIcon } from "lucide-react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

interface Props {
  cancelLabel?: string;
  isOpen: boolean;
  title?: string;
  message?: string;
  isElevated?: boolean;
  withCancel?: boolean;
  withSuccess?: boolean;
  successLabel?: string;
  variant?:
    | "outline"
    | "default"
    | "danger"
    | "primary"
    | "secondary"
    | "warning"
    | "dangerAlt";
  loading?: boolean;
  className?: string;
  contentClassName?: string;
  size?: "md" | "lg" | "sm" | "xl";
  disableSuccessBtn?: boolean;
  closeModalFromTitle?: boolean;
  footerClassName?: string;
  children: ReactNode;
  footerChildren?: (props: {
    loading?: boolean;
    onClose?: () => void;
    onSuccess?: () => void;
  }) => ReactNode;
  onClose: () => void;
  onSuccess?: () => void;
  onCancel?: () => void;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
}

const ModalTitle: FC<Partial<Props>> = ({
  closeModalFromTitle,
  title = "",
  message = "",
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <div className="amenda-modal pt-5 pb-4 w-full">
      <div className="flex items-center w-full">
        <div className="mt-3 sm:mt-0 w-full">
          <ModalBaseTitle title={title} className="font-sans text-center" />
          {message && (
            <p className="mt-2 font-apercu text-sm text-gray-500 text-center break-words">
              {t(message)}
            </p>
          )}
        </div>
        {closeModalFromTitle && (
          <IconButton
            className="mr-1"
            label="Close"
            Icon={XIcon}
            variant="default"
            onClick={onClose}
          />
        )}
      </div>
    </div>
  );
};

const ModalFooter: FC<Partial<Props>> = ({
  loading,
  withCancel,
  withSuccess,
  successLabel = "Save",
  variant = "primary",
  cancelLabel = "Cancel",
  disableSuccessBtn = false,
  onClose,
  onSuccess,
  footerChildren,
  footerClassName = "px-4 py-8 flex justify-end",
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    const handleListener = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
        onSuccess?.();
      }
    };
    document.addEventListener("keydown", handleListener);

    return () => document.removeEventListener("keydown", handleListener);
  });

  if (footerChildren) {
    return (
      <div className={clsx("amenda-modal", footerClassName)}>
        {footerChildren({
          loading,
          onClose,
          onSuccess,
        })}
      </div>
    );
  }

  if (!withCancel && !withSuccess) {
    return null;
  }
  return (
    <div className="amenda-modal py-3 flex justify-end space-x-2">
      {withCancel && (
        <div className="shrink">
          <Button type="button" onClick={onClose}>
            {t(cancelLabel)}
          </Button>
        </div>
      )}
      {withSuccess && (
        <div className="shrink">
          <Button
            type="button"
            variant={variant}
            loading={loading}
            disabled={disableSuccessBtn}
            onClick={() => onSuccess?.()}
          >
            {t(successLabel)}
          </Button>
        </div>
      )}
    </div>
  );
};

interface ModalContentProps {
  className?: string;
  children: ReactNode;
}

const ModalContent: FC<ModalContentProps> = ({ children, className }) => {
  return (
    <div className={clsx("py-2 h-full w-full amenda-modal", className)}>
      {children}
    </div>
  );
};

export const Modal: FC<Props> = ({
  children,
  isOpen,
  title,
  message,
  className,
  contentClassName,
  isElevated,
  size = "sm",
  withCancel = true,
  closeModalFromTitle = false,
  onCancel,
  onClose,
  onKeyDown,
  ...rest
}) => {
  return (
    <ModalBase
      isOpen={isOpen}
      size={size}
      className={className}
      isElevated={isElevated}
      onClose={onClose}
      onKeyDown={onKeyDown}
    >
      <ModalTitle
        title={title}
        message={message}
        closeModalFromTitle={closeModalFromTitle}
        onClose={onClose}
      />
      <ModalContent className={contentClassName}>{children}</ModalContent>
      <ModalFooter
        withCancel={withCancel}
        onClose={onCancel || onClose}
        {...rest}
      />
    </ModalBase>
  );
};
