import { IconButtonBase, Tooltip } from "@amenda-components/App";

import { ReactSVG } from "react-svg";
import clsx from "clsx";
import { useInView } from "react-intersection-observer";
import { useProjectStore } from "@amenda-domains/mutations";

export const ProjectExpandFormsButton = () => {
  const { ref, inView } = useInView();
  const setOpenProjectFormSection = useProjectStore(
    (state) => state.setOpenProjectFormSection,
  );

  const handleOpenForms = () => setOpenProjectFormSection(true);

  return (
    <div ref={ref}>
      <Tooltip id="autoSave" message="Your Data is automatically saved">
        <IconButtonBase
          className={clsx(
            "block lg:hidden bg-inherit transition ease-in-out duration-300 delay-150",
            {
              "fixed top-[75px] right-[10px] z-[60]": !inView,
            },
          )}
          size="sm"
          label="Expand to view forms"
          onClick={handleOpenForms}
        >
          <ReactSVG
            src="/images/sidebarExpand.svg"
            className="w-6 h-6 flex items-center justify-center"
          />
        </IconButtonBase>
      </Tooltip>
    </div>
  );
};
