import { FC, useState } from "react";

import { ChevronRightIcon } from "lucide-react";
import { ErrorMessage } from "./ErrorMessage";
import { IconButtonBase } from "@amenda-components/App";
import { Option } from "@amenda-types";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { onChangeOptions } from "@amenda-components/PageBuilder/common";
import { useTranslation } from "react-i18next";

interface CheckBoxProps {
  className?: string;
  error?: string;
  id: string;
  label?: string;
  options: Option[];
  selectedOptions?: string[];
  disabled?: boolean;
  onChange?: (value: any) => void;
  onBlur?: () => void;
}

type CheckBoxOptionProps = Pick<
  CheckBoxProps,
  "selectedOptions" | "disabled" | "onBlur"
> & {
  option: Option;
  parentId?: string;
  isExpanded: boolean;
  handleToggle: (option: Option) => void;
  handleChange: (option: Option, parentId?: string) => void;
};

const CheckBoxOption: FC<CheckBoxOptionProps> = ({
  option,
  parentId,
  disabled,
  isExpanded,
  selectedOptions = [],
  onBlur,
  handleChange,
  handleToggle,
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center" key={option.value}>
      <input
        id={option.value}
        name={option.label}
        type="checkbox"
        className={clsx("amenda-component h-5 w-5", {
          "text-gray-900": !disabled,
          "text-gray-500 cursor-not-allowed": disabled,
        })}
        checked={selectedOptions.includes(option.value)}
        disabled={disabled}
        onChange={() => handleChange(option, parentId)}
        onBlur={onBlur}
      />
      <div className="font-apercu ml-3 text-sm">
        <label
          htmlFor={option.value}
          className="font-medium text-gray-900 font-sm"
        >
          {t(option.label)}
          {Number.isFinite(Number(option?.count)) && (
            <span className="font-medium text-gray-500 ml-2">
              ({option?.count})
            </span>
          )}
        </label>
        {option.description && (
          <p className="font-apercu text-gray-500">{t(option.description)}</p>
        )}
      </div>
      {!isEmpty(option.children) && (
        <IconButtonBase
          className="ml-auto text-gray-600 p-0"
          size="sm"
          onClick={() => handleToggle(option)}
        >
          <ChevronRightIcon
            className={clsx("w-4 h-4", {
              "rotate-90": isExpanded,
            })}
          />
        </IconButtonBase>
      )}
    </div>
  );
};

export const CheckBox: FC<CheckBoxProps> = ({
  error,
  label,
  options,
  selectedOptions = [],
  className = "mb-2 mt-6",
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<Record<string, boolean>>({});

  const handleChange = (option: Option, parentId?: string) => {
    onChangeOptions({
      option,
      parentId,
      selectedOptions,
      onChange,
    });
  };

  const handleToggle = (option: Option) => {
    setIsOpen((prev) => ({
      ...prev,
      [option.value]: !prev[option.value],
    }));
  };

  return (
    <div className={className}>
      {label && <legend className="amenda-component-label">{t(label)}</legend>}
      <div className="mt-1 space-y-1">
        {options.map((option, index) => {
          return (
            <>
              <CheckBoxOption
                key={option.value + index.toString()}
                option={option}
                selectedOptions={selectedOptions}
                isExpanded={isOpen[option.value]}
                handleChange={handleChange}
                handleToggle={handleToggle}
                {...rest}
              />
              <Transition
                show={Boolean(isOpen[option.value])}
                key={option.value}
              >
                <div className="mt-1 space-y-1">
                  {option.children &&
                    option.children.map((o, i) => (
                      <CheckBoxOption
                        key={o.value + i.toString()}
                        option={o}
                        parentId={option.value}
                        selectedOptions={selectedOptions}
                        isExpanded={isOpen[o.value]}
                        handleToggle={handleToggle}
                        handleChange={handleChange}
                        {...rest}
                      />
                    ))}
                </div>
              </Transition>
            </>
          );
        })}
      </div>
      <ErrorMessage error={error} />
    </div>
  );
};
