import { AvailableForms, getFormBuilderSchema } from "@amenda-constants";
import { Button, Modal } from "@amenda-components/App";
import { Control, Controller, useForm } from "react-hook-form";
import { FC, ReactNode } from "react";
import { SingleSelect, TextField } from "@amenda-components/FormComponents";
import { getComponentsFromForms, sanitizeData } from "@amenda-utils";
import {
  transformFormBuilderComponent,
  transformFormBuilderComponents,
} from "./common";
import {
  useAppStore,
  useProjectStore,
  useUpdateForm,
} from "@amenda-domains/mutations";

import { PageComponentProps } from "@amenda-types";
import { useFormBuilderModifySystemRole } from "./hooks";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface ModalWrapperProps {
  isOpen?: boolean;
  config?: PageComponentProps;
  handleClose: () => void;
  children: (control: Control<any>) => ReactNode;
}

const formId = "formBuilderComponentForm";

const ModalWrapper: FC<ModalWrapperProps> = ({
  isOpen = false,
  config,
  handleClose,
  children,
}) => {
  const { t } = useTranslation();
  const formBuilderState = useAppStore((state) => state.formBuilderState);
  const { updateForm, loading } = useUpdateForm();
  const originalForms = useProjectStore((state) => state.originalForms);
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );

  const { control, handleSubmit, reset } = useForm<any>({
    resolver: yupResolver(getFormBuilderSchema(config as PageComponentProps)),
    values: transformFormBuilderComponents(config as PageComponentProps),
  });
  const { loading: isUpdatingPermissions } = useFormBuilderModifySystemRole();

  const { selectedForm } = formBuilderState ?? {};

  const cleanUpAfterClosing = () => {
    updateFormBuilderState("selectedCostGroupComponent", undefined);
  };

  const onSubmit = async (data: any) => {
    const form =
      selectedForm?.id &&
      originalForms.find((form) => form.id === selectedForm.id);
    const sanitizedData = sanitizeData(data);
    const component =
      form && form.components.find((c) => c.componentId === config?.id);
    const updatedComponent = transformFormBuilderComponent({
      componentConfig: config,
      values: sanitizedData,
      existingComponent: component,
      isNewComponent: !Boolean(component),
    });

    if (component) {
      await updateForm({
        id: form.id,
        components: [updatedComponent],
      });
      reset({});
      handleClose();
      cleanUpAfterClosing();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      loading={loading || isUpdatingPermissions}
      isElevated={true}
      closeModalFromTitle={true}
      withCancel={false}
      size="md"
      className="lg:w-5/12"
      title={config?.id ? "Edit component" : "Add component"}
      message={
        config?.id && `${t("Field type: ")} ${config?.component ?? "Layout"}`
      }
      onClose={handleClose}
      footerClassName="w-full border-t"
      footerChildren={({ loading, onClose }) => {
        return (
          <div className="w-full flex items-center justify-end pt-1 pb-2 bg-white">
            <Button type="button" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              loading={loading}
              type="submit"
              variant="primary"
              form={formId}
            >
              {t("Save")}
            </Button>
          </div>
        );
      }}
    >
      <form
        id={formId}
        className="px-2 max-h-[calc(100vh-10rem)] pb-8 overflow-y-auto"
        onSubmit={handleSubmit(onSubmit)}
      >
        {children(control)}
      </form>
    </Modal>
  );
};

export const FormBuilderCostGroupModal: FC = () => {
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const formBuilderState = useAppStore((state) => state.formBuilderState);
  const forms = useProjectStore((state) => state.forms);

  const { selectedCostGroupComponent, openCostGroupComponentModal } =
    formBuilderState ?? {};
  const components = getComponentsFromForms(
    forms?.[AvailableForms.CostGroupsQuantity] ?? [],
  ).filter((c) => Boolean(c?.properties));
  const { config } = selectedCostGroupComponent ?? {};

  const handleClose = () => {
    updateFormBuilderState("openCostGroupComponentModal", false);
  };

  return (
    <ModalWrapper
      isOpen={openCostGroupComponentModal}
      config={config as PageComponentProps}
      handleClose={handleClose}
    >
      {(control) => {
        return (
          <div className="flex flex-col space-y-2">
            <Controller
              name="label"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  id="label"
                  label="Label"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="endAddOnText"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <TextField
                  id="endAddOnText"
                  label="Add on text on the right"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="referenceQuantity"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => {
                return (
                  <SingleSelect
                    id="referenceQuantity"
                    label="Reference Quantity"
                    isClearable={false}
                    error={error?.message}
                    value={value}
                    hasMenuOverflow={true}
                    getOptionLabel={(component) => component?.properties?.label}
                    getOptionValue={(component) => component?.id}
                    onChange={onChange}
                    options={components}
                  />
                );
              }}
            />
          </div>
        );
      }}
    </ModalWrapper>
  );
};
