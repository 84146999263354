import "keen-slider/keen-slider.min.css";

import {
  AllowedAttachmentType,
  AvailableImageVariants,
  RoundedVariants,
} from "@amenda-types";
import { FC, useEffect, useState } from "react";
import { getFileType, thumbnailPlugin } from "./common";

import { Image } from "@amenda-components/App";
import clsx from "clsx";
import { useKeenSlider } from "keen-slider/react";

interface Props {
  files: any[];
  onClick?: (file: any) => void;
}

interface ThumbnailProps {
  file: any;
  lazyLoad?: boolean;
}

const getUrl = (file: any) => {
  if (getFileType(file) === AllowedAttachmentType.pdf) {
    return file.previewUrl;
  }
  return file.url;
};

const Thumbnail: FC<ThumbnailProps> = ({ file, lazyLoad = true }) => {
  return (
    <div className="h-full w-full">
      <Image
        url={getUrl(file)}
        size="pb-16/17"
        enableHighlighting={false}
        magicSize="galleryCropped"
        variant={AvailableImageVariants.cover}
        rounded={RoundedVariants?.none}
        lazyLoad={lazyLoad}
      />
    </div>
  );
};

export const SliderView: FC<Props> = ({ files, onClick }) => {
  const [selectedFileId, setSelectedFileId] = useState<any>();
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
  });
  const [thumbnailRef] = useKeenSlider<HTMLDivElement>(
    {
      mode: "free-snap",
      rubberband: true,
      initial: 0,
      slides: {
        perView: 6,
        spacing: 2,
      },
    },
    [thumbnailPlugin(instanceRef, setSelectedFileId)],
  );

  useEffect(() => {
    const slider = document.querySelector(
      ".keen-slider.thumbnail",
    ) as HTMLElement | null;
    const slideCount = slider
      ? slider.querySelectorAll(".keen-slider__slide").length
      : 0;

    if (slider && slideCount <= 1) {
      slider.style.display = "none";
    }
  }, []);

  return (
    <>
      <div className="mx-auto max-w-full sm:my-0 lg:max-w-full">
        <div ref={sliderRef} className="keen-slider">
          {files?.map((file) => (
            <div
              key={file.id}
              className="keen-slider__slide cursor-pointer"
              onClick={() => onClick?.(file)}
            >
              <Thumbnail file={file} />
            </div>
          ))}
        </div>
      </div>
      <div ref={thumbnailRef} className="keen-slider thumbnail space-x-1 pt-1">
        {files?.map((file) => (
          <div
            key={file.id}
            className={clsx("keen-slider__slide flex cursor-pointer", {
              "border-gray-900": file.id === selectedFileId,
              "border-transparent": file.id !== selectedFileId,
            })}
          >
            <Thumbnail file={file} lazyLoad={false} />
          </div>
        ))}
      </div>
    </>
  );
};
