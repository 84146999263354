import {
  Client,
  Exchange,
  RequestPolicy,
  fetchExchange,
  subscriptionExchange,
} from "urql";

import { SubscriptionClient } from "subscriptions-transport-ws";
import { abortExchange } from "./abortExchange";
import { asyncHeadersExchange } from "./asyncHeaderExchange";
import { devtoolsExchange } from "@urql/devtools";
import introspectedSchema from "./introspectionSchema.json";
import { isNil } from "lodash";
import { keys } from "./keys";
import { makeDefaultStorage } from "@urql/exchange-graphcache/default-storage";
import { offlineExchange } from "@urql/exchange-graphcache";
import { requestPolicyExchange } from "@urql/exchange-request-policy";
import { resolvers } from "./resolvers";
import { updates } from "./cacheUpdates";

const subscriptionClient = () => {
  const url = process.env.REACT_APP_BE_SUBSCRIPTION_URL || "";
  return new SubscriptionClient(url, {
    reconnect: true,
    reconnectionAttempts: 20,
    timeout: 20000,
    lazy: true,
  });
};

const dbVersion = "0.0.2";
export const storage = makeDefaultStorage({
  idbName: `amenda-cache-v[${dbVersion}]`, // The name of the IndexedDB database
  maxAge: 1, // The maximum age of the persisted data in days
});

const cache = offlineExchange({
  keys,
  storage,
  updates,
  resolvers,
  schema: introspectedSchema,
});
const requestPolicy = requestPolicyExchange({
  ttl: 3 * 60 * 1000, // 3 minutes.
  // An optional function that allows you to specify whether an operation should be upgraded.
  shouldUpgrade: (operation) => {
    const upgradeableOperations: RequestPolicy[] = [
      "cache-and-network",
      "network-only",
    ];

    return upgradeableOperations.includes(operation.context.requestPolicy);
  },
}) as Exchange;

export const client = new Client({
  url: process.env.REACT_APP_BE_URL || "",
  exchanges: [
    devtoolsExchange,
    requestPolicy,
    cache,
    asyncHeadersExchange,
    abortExchange,
    fetchExchange,
    subscriptionExchange({
      forwardSubscription: (request) => {
        if (
          !isNil(request.variables?.tenantId) &&
          !isNil(request.variables?.ownerId)
        ) {
          return subscriptionClient().request(request);
        }
        return { subscribe: () => ({ unsubscribe: () => {} }) };
      },
    }),
  ],
});
