import { FC, ReactNode, forwardRef } from "react";

import { linkTheme } from "@amenda-styles/theme";
import { useNavigate } from "react-router-dom";

interface LinkProps {
  className?: string;
  disabled?: boolean;
  children: ReactNode;
  path?: string;
  size?: "md" | "lg" | "sm" | "xs" | "xl";
  variant?: "primary" | "secondary" | "default" | "danger" | "secondaryAlt";
  onClick?: () => void;
}

export const Link: FC<LinkProps> = forwardRef<HTMLButtonElement, LinkProps>(
  (
    {
      children = "",
      className,
      size = "md",
      variant = "default",
      onClick,
      path,
      ...props
    },
    ref,
  ) => {
    const navigate = useNavigate();

    const handleClick = () => {
      if (path) {
        return navigate(path);
      }
      return onClick?.();
    };
    return (
      <button
        type="button"
        className={linkTheme({
          size,
          variant,
          className,
          disabled: !!props.disabled,
        })}
        onClick={handleClick}
        ref={ref}
        {...props}
      >
        {children}
      </button>
    );
  },
);
