import { AllowedContactType, AppRoutes } from "@amenda-types";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/24/outline";
import { capitalize, isNil } from "lodash";
import { flattenUserDetails, getUserName } from "./common";

import { ArrowUpRightIcon } from "@heroicons/react/24/solid";
import { Chip } from "@amenda-components/App";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useUsersStore } from "@amenda-domains/mutations";

interface Props {
  contact?: any;
  roles?: string[];
}

export const ProjectContactCard: FC<Props> = ({ contact, roles }) => {
  const navigate = useNavigate();
  const setSelectedUserId = useUsersStore((state) => state.setSelectedUserId);

  const user = flattenUserDetails(contact);

  const handleClick = () => {
    const path = isNil(contact?.type)
      ? AppRoutes.Colleagues
      : AppRoutes.Contacts;

    setSelectedUserId(contact);
    navigate(path);
  };

  return (
    <div
      className="group/projectContactCard flex items-center cursor-pointer border border-gray-200 px-1 py-2 space-x-2 mb-4 min-h-[4rem]"
      onClick={handleClick}
    >
      <div className="flex-1 min-w-0">
        <div className="w-full pl-1 overflow-hidden">
          <div className="flex">
            <div className="flex-none w-5 h-5 mt-1 mr-2">
              {user?.type === AllowedContactType.company ? (
                <BuildingOfficeIcon fontSize={5} />
              ) : (
                <UserIcon fontSize={1} />
              )}
            </div>
            <div className="flex-initial w-64 mt-1 truncate font-apercu">
              {getUserName(user)}
              <p className="amenda-contact-subline hidden lg:block">
                {user?.address?.name}
              </p>
            </div>
          </div>
        </div>
        {roles && (
          <div className="w-full pl-8 flex flex-wrap mt-2">
            {(roles ?? []).map((role) => (
              <div className="mb-1 mr-1" key={role}>
                <Chip label={capitalize(role)} />
              </div>
            ))}
          </div>
        )}
      </div>
      <span
        className="pointer-events-none relative justify-center right-2 text-[#2286e0] group-hover/projectContactCard:text-gray-900 hidden lg:block"
        aria-hidden="true"
      >
        <ArrowUpRightIcon className="h-5 w-5" />
      </span>
    </div>
  );
};
