import {
  COLLECTION_FRAGMENT,
  COLLECTION_SUBSCRIPTION_FRAGMENT,
  FORM_FRAGMENT,
} from "@amenda-domains/fragments/forms";
import { gql, useClient, useSubscription } from "urql";
import { useCallback, useState } from "react";
import {
  useFormStore,
  useProjectStore,
  useTenantStore,
  useUsersStore,
} from "@amenda-domains/mutations";

export const GET_ALL_FORMS = gql`
  ${FORM_FRAGMENT}
  query GetAllForms(
    $name: String
    $category: String
    $order: Int
    $isDeleted: Boolean
  ) {
    getAllForms(
      name: $name
      category: $category
      order: $order
      isDeleted: $isDeleted
    ) {
      ...FormFragment
    }
  }
`;

export const useGetAllForms = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setForms = useProjectStore((state) => state.setForms);

  const getAllForms = useCallback(async () => {
    setLoading(true);
    const { data } = await client.query(GET_ALL_FORMS, {
      isDeleted: false,
    });
    if (data?.getAllForms) {
      setForms(data.getAllForms);
    }
    setLoading(false);
  }, [client, setForms]);

  return {
    loading,
    getAllForms,
  };
};

export const GET_ALL_COLLECTIONS = gql`
  query GetAllCollections(
    $id: ID
    $name: String
    $shareable: Boolean
    $isDeleted: Boolean
    $collectionType: AllowedCollectionType
  ) {
    getAllCollections(
      _id: $id
      name: $name
      shareable: $shareable
      isDeleted: $isDeleted
      collectionType: $collectionType
    ) {
      id: _id
      name
      keywords
      ownerId
      resourceIds
      collectionType
      shares {
        userIds
        userRolesIds
        expirationDate
      }
    }
  }
`;

export const useGetAllCollections = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setCollectionsByType = useFormStore(
    (state) => state.setCollectionsByType,
  );

  const getAllCollections = useCallback(
    async ({ context = {}, ...variables }: Record<string, any> = {}) => {
      setLoading(true);
      const { data } = await client.query(
        GET_ALL_COLLECTIONS,
        variables,
        context,
      );
      if (data?.getAllCollections) {
        setCollectionsByType(data.getAllCollections, variables.collectionType);
      }
      setLoading(false);
    },
    [client, setCollectionsByType],
  );

  return {
    loading,
    getAllCollections,
  };
};

const GET_COLLECTION = gql`
  query GetCollection($id: ID, $name: String, $shareable: Boolean) {
    getCollection(_id: $id, name: $name, shareable: $shareable) {
      ...CollectionFragment
    }
  }
  ${COLLECTION_FRAGMENT}
`;

export const useGetCollection = () => {
  const client = useClient();
  const [loading, setLoading] = useState(false);
  const setSelectedCollectionByType = useFormStore(
    (state) => state.setSelectedCollectionByType,
  );

  const getCollection = useCallback(
    async (variables: Record<string, any> = {}) => {
      setLoading(true);
      const { data } = await client.query(GET_COLLECTION, variables);
      if (data?.getCollection) {
        setSelectedCollectionByType(data.getCollection);
      }
      setLoading(false);
    },
    [client, setSelectedCollectionByType],
  );

  return {
    loading,
    getCollection,
  };
};

const COLLECTION_CREATED_SUBS = gql`
  ${COLLECTION_SUBSCRIPTION_FRAGMENT}
  subscription CollectionCreated($ownerId: String!, $tenantId: String!) {
    collectionCreated(ownerId: $ownerId, tenantId: $tenantId) {
      ...CollectionSubsFragment
    }
  }
`;

const COLLECTION_UPDATED_SUBS = gql`
  ${COLLECTION_SUBSCRIPTION_FRAGMENT}
  subscription CollectionUpdated($ownerId: String!, $tenantId: String!) {
    collectionUpdated(ownerId: $ownerId, tenantId: $tenantId) {
      ...CollectionSubsFragment
    }
  }
`;

const COLLECTION_DELETED_SUBS = gql`
  subscription CollectionDeleted($ownerId: String!, $tenantId: String!) {
    collectionDeleted(ownerId: $ownerId, tenantId: $tenantId) {
      id: _id
      isDeleted
      tenantId
      collectionType
    }
  }
`;

export const useCollectionCreatedSubscription = () => {
  const currentUser = useUsersStore((state) => state.currentUser);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  useSubscription(
    {
      query: COLLECTION_CREATED_SUBS,
      variables: {
        ownerId: currentUser?.id,
        tenantId: primaryTenant?.tenantId,
      },
    },
    () => {},
  );
};

export const useCollectionUpdatedSubscription = () => {
  const currentUser = useUsersStore((state) => state.currentUser);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  useSubscription(
    {
      query: COLLECTION_UPDATED_SUBS,
      variables: {
        ownerId: currentUser?.id,
        tenantId: primaryTenant?.tenantId,
      },
    },
    () => {},
  );
};

export const useCollectionDeletedSubscription = () => {
  const currentUser = useUsersStore((state) => state.currentUser);
  const primaryTenant = useTenantStore((state) => state.primaryTenant);

  useSubscription(
    {
      query: COLLECTION_DELETED_SUBS,
      variables: {
        ownerId: currentUser?.id,
        tenantId: primaryTenant?.tenantId,
      },
    },
    () => {},
  );
};
