import {
  Active,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  Over,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { FC, useState } from "react";
import {
  FormBuilderDragOverlayItem,
  FormBuilderSortDroppable,
} from "./FormBuilderDndComponents";
import { HelperMessage, IconButtonBase } from "@amenda-components/App";
import {
  SortableContext,
  arrayMove,
  rectSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { getComponentsFromForms, getHeaderComponents } from "@amenda-utils";
import {
  useAppStore,
  useProjectStore,
  useUpdateForm,
} from "@amenda-domains/mutations";

import { FormCategories } from "@amenda-constants";
import { GripIcon } from "lucide-react";
import { ReadOnlyHeaders } from "@amenda-components/PageBuilder";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import sortBy from "lodash/sortBy";
import { useGetFormsByCategory } from "./hooks";

const isDragValid = (active: Active, over: Over) => {
  return active.id !== over.id;
};

const handleUpdatedComponents = ({
  originalForms,
  headerComponents,
  selectedCategory,
  updatedComponentIds,
}: {
  originalForms: any[];
  headerComponents: any[];
  updatedComponentIds: string[];
  selectedCategory?: FormCategories;
}) => {
  const components: any[] = [];
  const componentIdByForm: Record<string, any> = {};
  const componentsByForm: Record<string, any[]> = {};
  const availableForms = originalForms.filter((f) =>
    f.category.startsWith(selectedCategory),
  );
  const originComponents = getComponentsFromForms(availableForms);

  headerComponents.forEach((c) => {
    componentIdByForm[c.id] = c.formId;
  });

  updatedComponentIds.forEach((id, i) => {
    const index = originComponents.findIndex((c) => id === c.componentId);
    if (index < 0) return;
    const component = originComponents[index];
    if (component.headerProperties?.order === i) return;
    components.push({
      ...component,
      headerProperties: {
        ...component.headerProperties,
        order: i,
      },
    });
  });

  components.forEach((c) => {
    const formId = componentIdByForm[c.componentId];
    componentsByForm[formId] = [...(componentsByForm[formId] ?? []), c];
  });
  return componentsByForm;
};

export const FormBuilderReadOnlyHeaders: FC = () => {
  const [activeId, setActiveId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );
  const { updateForm } = useUpdateForm();
  const { availableForms } = useGetFormsByCategory();
  const originalForms = useProjectStore((state) => state.originalForms);
  const formBuilderState = useAppStore((state) => state.formBuilderState);

  const { selectedCategory } = formBuilderState ?? {};
  const components = getComponentsFromForms(availableForms);
  const headerComponents = getHeaderComponents(components);
  const sortedHeaderComponents = sortBy(
    headerComponents,
    "headerProperties.order",
  );
  const activeComponent = headerComponents.find((c) => c.id === activeId);

  const handleDragEnd = async (active: Active, over: Over) => {
    const activeComponent = active.data.current?.component;
    const overComponent = over.data.current?.component;
    const componentIds: any[] = active.data.current?.sortable?.items ?? [];

    if (!activeComponent || !overComponent) return;
    const newIndex = componentIds.indexOf(over.id);
    const oldIndex = componentIds.indexOf(active.id);
    const updatedComponentIds = arrayMove(componentIds, oldIndex, newIndex);
    const componentsByForm = handleUpdatedComponents({
      originalForms,
      selectedCategory,
      headerComponents,
      updatedComponentIds,
    });

    const formIds = Object.keys(componentsByForm);

    setLoading(true);
    if (!isEmpty(formIds)) {
      await Promise.all(
        formIds.map((id) =>
          updateForm({
            id,
            components: componentsByForm[id],
          }),
        ),
      );
    }
    setLoading(false);
  };

  if (isEmpty(headerComponents))
    return (
      <HelperMessage
        message="No header components found"
        helpText="Adjust your components to see them here"
      />
    );
  return (
    <DndContext
      sensors={sensors}
      modifiers={[restrictToFirstScrollableAncestor]}
      collisionDetection={closestCenter}
      onDragStart={(event) => {
        const { active } = event;

        setActiveId(String(active.id));
      }}
      onDragEnd={(event) => {
        const { active, over } = event;
        setActiveId(null);
        if (over && isDragValid(active, over)) {
          handleDragEnd(active, over);
        }
      }}
    >
      <SortableContext
        id="formBuilderReadOnlyHeaders"
        items={sortedHeaderComponents.map((c) => c.id)}
        strategy={rectSortingStrategy}
      >
        <div className="w-full pb-4 group/formBuilderReadOnlyHeader overflow-y-auto">
          <FormBuilderSortDroppable
            className={clsx(
              "w-full border border-dashed group-hover/formBuilderReadOnlyHeader:border-gray-300 border-transparent px-2 pb-4",
              {
                "animate-pulse": loading,
              },
            )}
          >
            <ReadOnlyHeaders isFormBuilder={true} components={components} />
          </FormBuilderSortDroppable>
        </div>
      </SortableContext>
      <DragOverlay>
        <FormBuilderDragOverlayItem>
          <div className="w-full flex items-center">
            <div>
              <IconButtonBase size="sm" className="cursor-grab px-0.5 py-1">
                <GripIcon className="w-5 h-5 stroke-[2]" />
              </IconButtonBase>
            </div>
            <span className="ml-2 truncate">
              {activeComponent?.properties?.label}
            </span>
          </div>
        </FormBuilderDragOverlayItem>
      </DragOverlay>
    </DndContext>
  );
};
