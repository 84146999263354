import { FC } from "react";

interface Props {
  className?: string;
}

export const EmptyIndicator: FC<Props> = ({
  className = "font-apercu text-lg w-full",
}) => {
  return <span className={className}>&#8212;</span>;
};
