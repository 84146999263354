import {
  createParentMap,
  getItemTree,
} from "@amenda-components/FormComponents/common";

import { FC } from "react";
import { FormComponentTypes } from "@amenda-constants";
import { ReactTreeSelectBreadCrumb } from "@amenda-components/FormComponents";
import { ReadOnlyKeywordsBase } from "@amenda-components/Shared/ReadOnlyKeywords";
import { ReadOnlyRegionalSelectBase } from "@amenda-components/Shared";
import { ReadOnlySearchAndSelectBase } from "./ReadOnlySearchAndSelect";
import { flattenUserDetails } from "@amenda-components/Contacts/common";
import { formatDate } from "@amenda-utils";

interface Props {
  components: any[];
  componentId: string;
  componentValue: any;
}

interface ResolveComponentsProps {
  component: any;
  value: any;
}

const ResolveComponents: FC<ResolveComponentsProps> = ({
  component,
  value,
}) => {
  const options: any[] = component?.properties?.options || [];
  const contactType = component?.properties?.contactType;
  let resolvedValues: any;

  switch (component.component) {
    case FormComponentTypes.Input:
      return <div>{value}</div>;
    case FormComponentTypes.AddressSearch:
      return <div>{value?.name}</div>;
    case FormComponentTypes.Badges:
    case FormComponentTypes.MultiSelect:
    case FormComponentTypes.Checkbox:
      if (!value) return null;
      if (Boolean(component?.properties?.isNested)) {
        const items = getItemTree(options);
        const parentMap = createParentMap(items);

        return (
          <div>
            <ReactTreeSelectBreadCrumb
              itemId={value}
              items={items}
              parentMap={parentMap}
            />
          </div>
        );
      }
      if (Array.isArray(value)) {
        resolvedValues = value
          .map(
            (v: any) =>
              options.find((option) => option.value === v)?.label || v,
          )
          .join(", ");
      } else {
        resolvedValues =
          options.find((option) => option.value === value)?.label || value;
      }
      return <div>{resolvedValues}</div>;
    case FormComponentTypes.Select:
    case FormComponentTypes.RadioButton:
      if (!value) return null;
      resolvedValues =
        options.find((option) => option.value === value)?.label || value;

      return <div>{resolvedValues}</div>;
    case FormComponentTypes.DatePicker:
      if (!value) return null;
      return <div>{formatDate(value)}</div>;
    case FormComponentTypes.DatePickerRange:
      return (
        <div>
          {[value?.to, value?.from]
            ?.filter(Boolean)
            ?.map((date) => formatDate(date))
            .join(" - ")}
        </div>
      );
    case FormComponentTypes.Switch:
      return (
        <div>
          {component.properties?.label}: {Boolean(value) ? "Ja" : "Nein"}
        </div>
      );
    case FormComponentTypes.Keyword:
      return (
        <ReadOnlyKeywordsBase keywords={value}>
          {(loading, selectedKeywords) => (
            <div>
              {selectedKeywords?.map((keyword) => keyword.name).join(", ")}
            </div>
          )}
        </ReadOnlyKeywordsBase>
      );
    case FormComponentTypes.RegionalSelect:
      return (
        <ReadOnlyRegionalSelectBase regionId={value}>
          {(region) => {
            return <div>{region?.city}</div>;
          }}
        </ReadOnlyRegionalSelectBase>
      );
    case FormComponentTypes.SearchAndSelect:
      return (
        <ReadOnlySearchAndSelectBase userIds={value} contactType={contactType}>
          {(users) => {
            return (
              <div>
                {users
                  .map((u) => {
                    const user = flattenUserDetails(u);
                    return [user?.lastName, user?.firstName, user?.companyName]
                      .filter(Boolean)
                      .join(" ");
                  })
                  .join(", ")}
              </div>
            );
          }}
        </ReadOnlySearchAndSelectBase>
      );
    default:
      return null;
  }
};

export const GroupByHeader: FC<Props> = ({
  components,
  componentId,
  componentValue,
}) => {
  const component = components.find(
    (component) => component.id === componentId,
  );

  return (
    <div className="w-full">
      <div className="text-gray-800 font-medium text-sm">
        {component && (
          <div key={component.id}>
            <ResolveComponents component={component} value={componentValue} />
          </div>
        )}
      </div>
    </div>
  );
};
