import { BellIcon, LogOutIcon, SearchIcon } from "lucide-react";
import {
  Button,
  Link as CustomLink,
  IconButtonBase,
  SlideOver,
  Tooltip,
} from "@amenda-components/App";
import { FC, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Bars3Icon } from "@heroicons/react/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { Modal } from "./Modal";
import { NavigationProps } from "@amenda-types";
import { SearchModal } from "@amenda-components/SearchComponents";
import { allBlue } from "@amenda-styles/theme";
import clsx from "clsx";
import { isEmpty } from "lodash";
import { matchUrls } from "@amenda-utils";
import { useAppStore, useTenantStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

interface Props {
  className?: string;
}

export const Logo: FC = () => {
  const primaryTenant = useTenantStore((state) => state.primaryTenant);
  return (
    <img
      alt="amenda"
      src={
        primaryTenant?.logoUrl
          ? primaryTenant?.logoUrl
          : "/images/logoDefault.svg"
      }
      className="max-w-[140px] lg:max-w-[140px] max-h-[45px]"
    />
  );
};

const NotificationModal: FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const notificationMessages = useAppStore(
    (state) => state.notificationMessages,
  );
  const clearNotificationMessage = useAppStore(
    (state) => state.clearNotificationMessage,
  );

  const handleClick = (message: any) => () => {
    window.open(message.path);
    clearNotificationMessage(message);
  };

  return (
    <Modal
      isElevated
      title="Notifications"
      isOpen={isOpen}
      size="md"
      onClose={onClose}
    >
      {!isEmpty(notificationMessages) ? (
        <>
          {notificationMessages.map((message) => (
            <div key={message.path}>
              <CustomLink
                className={clsx(
                  allBlue.menus.panelItem.lg,
                  `hover:${allBlue.menus.panelItemActive.lg} hover:no-underline cursor-pointer w-full py-1`,
                )}
                onClick={handleClick(message)}
              >
                {message.message}
              </CustomLink>
            </div>
          ))}
        </>
      ) : (
        <p className="w-full px-2 py-4 text-center text-gray-700 text-sm font-medium">
          {t("No notifications found!")}
        </p>
      )}
    </Modal>
  );
};

export const SidebarFooter: FC<Props> = ({
  className = "pt-8 pb-2 bottom-0 absolute",
}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  // const primaryTenant = useTenantStore((state) => state.primaryTenant);
  const sidebarCollapsed = useAppStore((state) => state.sidebarCollapsed);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  const handleLogout = () => navigate("/logout");

  return (
    <div className={className}>
      <NotificationModal isOpen={isOpen} onClose={handleClose} />
      <nav
        className={clsx("flex", {
          "flex-col-reverse": sidebarCollapsed,
          "flex-1 px-2 w-full flex-row": !sidebarCollapsed,
        })}
      >
        {/* {!sidebarCollapsed && primaryTenant?.logoUrl && <PoweredBy />} */}
        <Tooltip
          id="logoutBtn"
          message="Logout"
          {...(sidebarCollapsed
            ? {
                position: "top",
                positionStrategy: "fixed",
              }
            : {})}
        >
          <IconButtonBase
            size="sm"
            variant="secondary"
            className="rounded-md bg-inherit hover:text-gray-900 hover:bg-gray-50"
            onClick={handleLogout}
          >
            <LogOutIcon className="shrink-0 h-6 w-6" />
          </IconButtonBase>
        </Tooltip>
        <Tooltip
          id="notificationsBtn"
          message="Notifications"
          {...(sidebarCollapsed
            ? {
                position: "top",
                positionStrategy: "fixed",
              }
            : {})}
        >
          <IconButtonBase
            size="sm"
            variant="secondary"
            className="rounded-md bg-inherit hover:text-gray-900 hover:bg-gray-50"
            onClick={handleOpen}
          >
            <BellIcon className="shrink-0 h-6 w-6" />
          </IconButtonBase>
        </Tooltip>
      </nav>
    </div>
  );
};

interface SidebarProps {
  navigation: NavigationProps[];
  closeSlideOver?: () => void;
}

// export const PoweredBy: FC = () => {
//   return (
//     <div className="flex min-w-24 h-12">
//       <div className="flex ">
//         <div className="flex ">
//           <span className="text-xxs text-gray-500 font-medium">
//             Powered by{" "}
//             <img
//               alt="amenda"
//               width="30px"
//               src="/images/logo.svg"
//               className="pl-2 pt-1 h-[15px] w-auto"
//             />
//           </span>
//         </div>
//       </div>
//     </div>
//   );
// };

export const SidebarMenuItems: FC<SidebarProps> = ({
  navigation,
  closeSlideOver,
}) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const toggleSearchModal = useAppStore((state) => state.toggleSearchModal);
  const sidebarCollapsed = useAppStore((state) => state.sidebarCollapsed);
  const setSidebarCollapsed = useAppStore((state) => state.setSidebarCollapsed);

  const isSlideOver = Boolean(closeSlideOver);

  const handleSearch = () => toggleSearchModal(true);

  const handleOpenSidebar = () => {
    setSidebarCollapsed(false);
  };

  const handleClick = (navigation: any) => () => {
    closeSlideOver?.();
    navigate(navigation.path);
  };

  return (
    <>
      <SearchModal />
      <nav
        className={clsx("space-y-1", {
          "pl-4": !sidebarCollapsed,
        })}
      >
        {!isSlideOver && (
          <Button
            size="lg"
            className={clsx(
              "group rounded-tl-md rounded-bl-md text-gray-400 h-12 py-2 text-sm bg-inherit",
              {
                "hover:bg-white hover:text-gray-900 mt-4 pl-1 w-full":
                  !sidebarCollapsed,
                "mt-7 px-0 w-full flex justify-center hover:bg-inherit hover:text-gray-900":
                  sidebarCollapsed,
              },
            )}
            onClick={handleSearch}
          >
            <SearchIcon className="shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500" />
            {!sidebarCollapsed && (
              <span className="ml-3 opacity-0 group-hover:opacity-100 ease-in duration-300">
                {t("Search all")}
              </span>
            )}
          </Button>
        )}
        {sidebarCollapsed ? (
          <div className="flex-col items-center">
            <IconButtonBase
              size="sm"
              variant="secondary"
              className="rounded-md bg-inherit hover:text-gray-900 hover:bg-gray-50"
              onClick={handleOpenSidebar}
            >
              <img src="/images/sidebarExpand.svg" className="h-6 w-6" alt="" />
            </IconButtonBase>
          </div>
        ) : (
          navigation.map((navigation) => {
            const pathMatches = matchUrls(pathname, navigation);

            return (
              <Button
                key={navigation.path}
                size="lg"
                className={clsx(
                  "rounded-tl-md rounded-bl-md text-gray-200 bg-inherit w-full h-12 xl:h-16 text-sm pl-4 pr-2",
                  {
                    "bg-white text-gray-900": pathMatches,
                    "text-gray-500 hover:bg-gray-50 hover:text-gray-900":
                      !pathMatches,
                  },
                )}
                onClick={handleClick(navigation)}
              >
                <span>{t(navigation.label)}</span>
              </Button>
            );
          })
        )}
      </nav>
    </>
  );
};

export const SidebarMobileMenu: FC<SidebarProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleSearchModal = useAppStore((state) => state.toggleSearchModal);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);
  const handleSearch = () => toggleSearchModal(true);

  return (
    <>
      <SlideOver show={isOpen} title="Menu" onClose={handleClose}>
        <div className="w-full h-full bg-[#F3F4F6] py-2">
          <SidebarMenuItems {...props} closeSlideOver={handleClose} />
          <SidebarFooter />
        </div>
      </SlideOver>
      <div className="lg:hidden block mb-12">
        <div className="absolute flex justify-between bg-white z-[60] w-full border-b border-gray-200 px-1 pt-0.5 pb-1">
          <div className="flex items-center space-x-2">
            <IconButtonBase
              size="sm"
              variant="clean"
              className="hover:bg-white hover:text-gray-900"
              onClick={handleOpen}
            >
              <Bars3Icon className="h-8 w-8" />
            </IconButtonBase>
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <IconButtonBase
            size="sm"
            variant="clean"
            className="hover:bg-white hover:text-gray-900"
            onClick={handleSearch}
          >
            <MagnifyingGlassIcon className="h-6 w-6" />
          </IconButtonBase>
        </div>
      </div>
    </>
  );
};
