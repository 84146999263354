import {
  AddOfficeUserModal,
  AdminSettingsLayout,
  EditOfficeUserModal,
  UsersTableWrapper,
} from "@amenda-components/Settings";
import { FC, useEffect } from "react";
import { useSyncUsers, useUsersStore } from "@amenda-domains/mutations";

import { Button } from "@amenda-components/App";
import { MicrosoftLoginButton } from "@amenda-components/Auth";
import { PaginationLimit } from "@amenda-constants";
import { loginRequest } from "@amenda-domains/msGraphConfig";
import { useGetAllUsers } from "@amenda-domains/queries/users";
import { useMsal } from "@azure/msal-react";
import { useTranslation } from "react-i18next";

export const ManageUsers: FC = () => {
  const { t } = useTranslation();
  const { getAllUsers, loading } = useGetAllUsers();
  const setCreateOfficeUserModal = useUsersStore(
    (state) => state.setCreateOfficeUserModal,
  );
  const { instance } = useMsal();
  const { syncUsers } = useSyncUsers();

  const handleSyncUsers = () => {
    instance
      .loginPopup(loginRequest)
      .then((response) => {
        instance.setActiveAccount(response.account);
        syncUsers();
      })
      .catch((e: any) => {});
  };

  const openNewUserModal = () => {
    setCreateOfficeUserModal(true);
  };

  useEffect(() => {
    getAllUsers({
      isDeleted: false,
      limit: PaginationLimit.contacts,
    });
  }, [getAllUsers]);

  return (
    <AdminSettingsLayout>
      <AddOfficeUserModal />
      <EditOfficeUserModal />
      <div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="amenda-settings-heading">{t("Your Team")}</h1>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none justify-end">
            <MicrosoftLoginButton
              onClick={handleSyncUsers}
              name="Mit MS Entra Synchronisieren"
            />
          </div>
          <div className="mt-4 sm:mt-0 ml-4 sm:flex-none">
            <Button
              type="button"
              variant="primary"
              size="md"
              onClick={openNewUserModal}
              className="amenda-button"
            >
              + {t("Add Teammember")}
            </Button>
          </div>
        </div>
        <div className="mt-4">
          <UsersTableWrapper userLoading={loading} />
        </div>
      </div>
    </AdminSettingsLayout>
  );
};
