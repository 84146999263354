import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { GeneralPermissionTypes, Pagination, ProjectView } from "@amenda-types";
import { ProjectGridView, ProjectTableView } from "@amenda-components/Projects";
import { useProjectStore, useSettingsStore } from "@amenda-domains/mutations";

import { BlockedRoutes } from "@amenda-constants";
import { BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { HelperMessage } from "@amenda-components/App";
import { ProjectMapView } from "@amenda-components/Maps";
import { getComponentsById } from "@amenda-utils";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";

const getValue = ({
  value,
  pagination,
  defaultValue,
}: {
  value: any;
  defaultValue: any;
  pagination?: Pagination;
}) => {
  if (pagination?.docsCount && pagination.docsCount > 0) {
    return value;
  }
  return defaultValue;
};

interface Props {
  isCollection?: boolean;
}

export const AllProjectsView: FC<Props> = ({ isCollection }) => {
  const navigate = useNavigate();
  const pagination = useProjectStore((state) => state.pagination);
  const projects = useProjectStore((state) => state.projects);
  const projectView = useProjectStore((state) => state.projectView);
  const components = useProjectStore((state) => state.projectFormComponents);
  const isFetching = useProjectStore((state) => state.isFetching);
  const isSearching = useProjectStore((state) => state.isSearching);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  const componentsById = getComponentsById(components);
  const projectsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Projects,
  );

  const handleClick = () => {
    navigate(BlockedRoutes.ProjectNew);
  };

  if (!isFetching && !isSearching && isEmpty(projects)) {
    return (
      <HelperMessage
        actionText="Create Project"
        Icon={BuildingOffice2Icon}
        message={getValue({
          pagination,
          value: "No Projects Found",
          defaultValue: projectsGeneralPermissions[
            GeneralPermissionTypes.Create
          ]
            ? "Get started by creating a new project."
            : "No Projects found",
        })}
        helpText={getValue({
          pagination,
          value: "Try changing your search filters",
          defaultValue: projectsGeneralPermissions[
            GeneralPermissionTypes.Create
          ]
            ? "Please create a Project first"
            : "Contact your administrator to add projects",
        })}
        onClick={getValue({
          pagination,
          value: undefined,
          defaultValue:
            projectsGeneralPermissions[GeneralPermissionTypes.Create] &&
            handleClick,
        })}
      />
    );
  }
  return (
    <div>
      {projectView === ProjectView.Gallery && (
        <ProjectGridView
          isCollection={isCollection}
          projects={projects}
          componentsById={componentsById}
        />
      )}
      {projectView === ProjectView.Table && (
        <ProjectTableView
          isCollection={isCollection}
          projects={projects}
          components={components}
          componentsById={componentsById}
        />
      )}
      {projectView === ProjectView.Map && (
        <ProjectMapView projects={projects} />
      )}
    </div>
  );
};
