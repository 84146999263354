import { Logo, SidebarFooter, SidebarMenuItems } from "./SidebarComponents";
import { FC } from "react";
import { Link } from "react-router-dom";
import { NavigationProps } from "@amenda-types";
import clsx from "clsx";
import { useAppStore } from "@amenda-domains/mutations";
import { Tooltip } from "@amenda-components/App";

interface Props {
  navigation: NavigationProps[];
}

export const Sidebar: FC<Props> = ({ navigation }) => {
  const sidebarCollapsed = useAppStore((state) => state.sidebarCollapsed);
  const setSidebarCollapsed = useAppStore((state) => state.setSidebarCollapsed);
  const toggleSidebar = () => setSidebarCollapsed(!sidebarCollapsed);

  return (
    <div
      className={clsx(
        "group relative hidden h-full lg:flex flex-col z-40 transition-width duration-300 ease-in-out",
        {
          "w-[210px]": !sidebarCollapsed,
          "w-0": sidebarCollapsed,
        },
      )}
    >
      <div className="absolute top-0 lg:top-[40px] right-2 z-20">
        {!sidebarCollapsed && (
          <Tooltip
            id="toggleSidebar"
            message="Toggle Sidebar"
            position="bottom"
          >
            <img
              alt="Toggle Sidebar"
              className="w-4 h-4 cursor-pointer"
              onClick={toggleSidebar}
              src={
                sidebarCollapsed
                  ? "/images/sidebarExpand.svg"
                  : "/images/sidebarCollapse.svg"
              }
            />
          </Tooltip>
        )}
      </div>
      <div className="flex flex-col flex-grow pt-[15px] pb-2 bg-[#F3F4F6] h-full">
        <div
          className={clsx("relative flex flex-col overflow-y-auto h-full", {
            "items-center": sidebarCollapsed,
          })}
        >
          <div
            className={clsx("py-1", {
              "px-4": !sidebarCollapsed,
            })}
          >
            <Link to="/">
              <Logo />
            </Link>
          </div>
          <SidebarMenuItems navigation={navigation} />
          <SidebarFooter
            className={clsx("sticky bottom-0 mt-auto", {
              "h-12": !sidebarCollapsed,
            })}
          />
        </div>
      </div>
    </div>
  );
};
