import { Asterisk, PencilIcon, TrashIcon, Variable } from "lucide-react";

import { FC } from "react";
import { FormComponentProps } from "@amenda-types";
import { IconButtonBase } from "@amenda-components/App";
import isEmpty from "lodash/isEmpty";
import { useAppStore } from "@amenda-domains/mutations";

export const FormBuilderComponent: FC<FormComponentProps> = ({
  config,
  global,
}) => {
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );

  const componentLabel =
    config?.properties?.readOnlyLabel ??
    config?.properties?.label ??
    config?.id;
  const isRequired = Array.isArray(config?.validation?.properties)
    ? (config?.validation?.properties ?? []).some((c: any) =>
        Boolean(c?.then?.required ?? c?.otherwise?.required),
      )
    : Boolean(config?.validation?.properties?.required);
  const hasDisplayCondition = !isEmpty(config?.display);

  const handleOpen = () => {
    updateFormBuilderState("openEditComponentModal", true);
    updateFormBuilderState("selectedFormComponent", {
      config,
      global,
    });
  };

  const handleOpenDeleteModal = () => {
    updateFormBuilderState("deleteComponent", true);
    updateFormBuilderState("selectedFormComponent", {
      config,
      global,
    });
  };

  return (
    <div className="relative w-full h-full flex overflow-hidden truncate items-center justify-between p-1">
      <div className="flex items-start pl-1 space-x-1">
        {isRequired && <Asterisk className="w-4 h-4 text-red-600" />}
        <span className="text-sm">{componentLabel}</span>
        {hasDisplayCondition && <Variable className="w-4 h-4 text-gray-900" />}
      </div>
      <div className="absolute right-0 bg-white items-center justify-end flex space-x-1 invisible group-hover/half-col:visible group-hover/full-col:visible group-hover/one-third-col:visible group-hover/two-thirds-col:visible">
        <IconButtonBase
          size="sm"
          variant="clean"
          className="p-1"
          onClick={handleOpen}
        >
          <PencilIcon className="w-5 h-5" />
        </IconButtonBase>
        {!Boolean(config?.isAppSpecific) && (
          <IconButtonBase
            size="sm"
            variant="danger"
            className="p-1"
            onClick={handleOpenDeleteModal}
          >
            <TrashIcon className="w-5 h-5" />
          </IconButtonBase>
        )}
      </div>
    </div>
  );
};
