import {
  AllowedContactType,
  FormTab,
  GeneralPermissionTypes,
  Option,
  TabItem,
} from "@amenda-types";
import { FormComponentTypes, PaginationLimit } from "@amenda-constants";
import {
  getComponentsById,
  getComponentsFromForms,
  getFullName,
  getGroupableFromForms,
  getSearchComponents,
  sanitizeData,
  sanitizeNestedData,
} from "@amenda-utils";

import { ColumnDef } from "@tanstack/react-table";
import { ReactNode } from "react";
import { addMatchForCollection } from "@amenda-components/Shared/common";
import { dynamicTableColumnsNoPermissions } from "@amenda-components/PageBuilder/dynamicTableColumns";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";
import isObject from "lodash/isObject";
import uniq from "lodash/uniq";

export interface ContactSearchProps {
  searchTerm?: string;
  collection?: any;
  groupByComponentIds?: string[];
  contactType?: AllowedContactType;
  match?: Record<string, any>;
}

export const isUserSearchFiltersEmpty = (props?: ContactSearchProps) => {
  const { searchTerm, groupByComponentIds } = props ?? {};
  return isEmpty(searchTerm) && isEmpty(groupByComponentIds);
};

export const isAllUserSearchFiltersEmpty = (filters: ContactSearchProps) => {
  if (isEmpty(filters)) return true;
  return Object.values(filters).every((value) => isEmpty(value));
};

export const getSelectedRoles = ({
  allRoles,
  userId,
  contactsWithRoles = [],
}: {
  contactsWithRoles?: any[];
  userId: string;
  allRoles: Option[];
}) => {
  const foundRole = contactsWithRoles.find((r) => r.userId === userId);
  if (isEmpty(foundRole?.roles)) {
    return [];
  }

  const selectedRoles: any[] = foundRole.roles.map((r: any) => {
    const role = allRoles.find(({ value }) => r === value);
    return role;
  });
  return selectedRoles;
};

export const getUserName = (user: any) => {
  const fUser = flattenUserDetails(user);

  return fUser?.type === AllowedContactType.company
    ? fUser?.companyName
    : [fUser?.firstName, fUser?.lastName].filter(Boolean).join(" ");
};

export enum UserTabs {
  projects = "projects",
  relatedCompanies = "relatedCompanies",
  relatedPersons = "relatedPersons",
}

export const staticContactLabels = {
  activity: {
    label: "Activities",
    value: "activity",
  },
  projects: {
    label: "Projects list",
    value: UserTabs.projects,
  },
  [UserTabs.relatedCompanies]: {
    label: "Related Company",
    value: UserTabs.relatedCompanies,
  },
  [UserTabs.relatedPersons]: {
    label: "Related Persons",
    value: UserTabs.relatedPersons,
  },
};

const addActivityStaticTab = (contactGeneralPermissions: any) => {
  return Boolean(contactGeneralPermissions[GeneralPermissionTypes.Activity])
    ? [staticContactLabels.activity]
    : [];
};

type GetUserTabsArgs = {
  editingMode: boolean;
  type: AllowedContactType;
  contactForms: FormTab[];
  contactGeneralPermissions: any;
};
export const getUserTabs = ({
  type,
  editingMode,
  contactForms,
  contactGeneralPermissions,
}: GetUserTabsArgs) => {
  const sharedTabs: TabItem[] = [
    ...contactForms.map((form) => ({
      label: form.name,
      value: form.id,
    })),
  ];

  sharedTabs.push({
    ...staticContactLabels.projects,
    disabled: editingMode,
  });
  if (AllowedContactType.office === type) {
    sharedTabs.push(...addActivityStaticTab(contactGeneralPermissions));
    return sharedTabs;
  } else if (AllowedContactType.company === type) {
    sharedTabs.push({
      ...staticContactLabels.relatedPersons,
      disabled: editingMode,
    });
    sharedTabs.push(...addActivityStaticTab(contactGeneralPermissions));
    return sharedTabs;
  }
  sharedTabs.push({
    ...staticContactLabels.relatedCompanies,
    disabled: editingMode,
  });
  sharedTabs.push(...addActivityStaticTab(contactGeneralPermissions));
  return sharedTabs;
};

export const groupUsersAlphabetically = (users: any[] = [], sortBy = "") => {
  const selectedUser = users[0];
  const groupedUsers = groupBy(users, (u) => {
    const name = get(u, sortBy, "") || ""; // TODO: Let the user decide how to group

    return name?.toUpperCase()?.charAt(0);
  });
  return { groupedUsers, selectedUser };
};

export const isUserSelected = (contact: any, selectedContact?: any) => {
  return contact.id === selectedContact?.id;
};

export const transformFormToUsers = ({
  defaultShareType,
  form = {},
  id,
  type,
  uid,
  existingData,
}: {
  defaultShareType?: string;
  form?: Record<string, any>;
  id?: string;
  type: AllowedContactType;
  uid?: string;
  existingData?: any;
}) => {
  const { firstName, lastName, photoURL, ...rest } = form;
  let data: any = {
    firstName,
    lastName,
    photoURL,
    _id: id,
  };

  if (defaultShareType && !id) {
    data = {
      ...data,
      share: {
        type: defaultShareType,
      },
    };
  }

  if (type === AllowedContactType.office) {
    data = {
      ...data,
      uid,
      userDetails: rest,
      fullName: getFullName(
        firstName ?? existingData?.firstName,
        lastName ?? existingData?.lastName,
      ),
    };
  } else if (type === AllowedContactType.person) {
    data = {
      ...data,
      type,
      contactDetails: rest,
      fullName: getFullName(
        firstName ?? existingData?.firstName,
        lastName ?? existingData?.lastName,
      ),
    };
  } else {
    data = {
      ...data,
      type,
      contactDetails: rest,
      fullName: rest?.companyName,
    };
  }
  return sanitizeNestedData(data);
};

export const transformUsersToForm = (users: Record<string, any> = {}) => {
  const { userDetails, contactDetails, ...rest } = users;
  const form = {
    ...rest,
    ...(userDetails || {}),
    ...(contactDetails || {}),
  };

  return sanitizeData(form);
};

export const flattenUserDetails = (user: Record<string, any> = {}) => {
  const { userDetails, contactDetails, ...rest } = user;
  return { ...rest, ...(userDetails || {}), ...(contactDetails || {}) };
};

export const getSelectedUsers = (users: any[], values: any) => {
  const selectedUsers: any[] = [];
  const availableValues = Array.isArray(values) ? values : [values];

  availableValues.forEach((v) => {
    const id = v?.value || v;
    const user = users.find((c) => id && id === c?.id);
    if (user) {
      selectedUsers.push(user);
    }
  });

  return selectedUsers;
};

export const transformUsersToOptions = (users: any[]) => {
  return users.map((user) => {
    const flatUser = flattenUserDetails(user);

    return {
      value: flatUser?.id,
      label: getUserName(flatUser),
    };
  });
};

export const handleChangeToArray = (value: any) => {
  if (value) {
    return isArray(value) ? value : [value];
  }
  return [];
};

export const getValueFromUserDetail = (
  data: Record<string, any>,
  key: string,
) => {
  if (isArray(data[key])) {
    const values: any[] = data[key] || [];
    return values.map((v) => v.value)?.join(", ");
  } else if (isObject(data[key])) {
    const datum: any = data[key];
    return datum?.name || datum?.value || datum;
  } else {
    return data[key];
  }
};

export const isSelectedUser = (users: string[], user: any) => {
  return users.some((id) => id === user.id);
};

export const getDynamicColumnIds = (
  formsByContactType: Record<AllowedContactType, FormTab[]>,
) => {
  const forms = [
    ...formsByContactType[AllowedContactType.office],
    ...formsByContactType[AllowedContactType.person],
    ...formsByContactType[AllowedContactType.company],
  ];

  const components = getComponentsFromForms(forms);
  const allowedComponentsIds = components
    .filter((component) => Boolean(component.component))
    .filter(
      (component) => component.component !== FormComponentTypes.SearchAndSelect,
    )
    .map((component) => component.id);
  const dynamicColumnIds = uniq(allowedComponentsIds);

  return {
    dynamicColumnIds,
    componentsById: getComponentsById(components),
  };
};

export const getDynamicContactColumns = (
  formsByContactType: Record<AllowedContactType, FormTab[]>,
) => {
  const { dynamicColumnIds, componentsById } =
    getDynamicColumnIds(formsByContactType);
  return dynamicTableColumnsNoPermissions({
    componentsById,
    dynamicColumnIds,
  });
};

export interface StaticColumnProps {
  id: string;
  label: string;
  contactType?: AllowedContactType;
  getCell: (user: any) => ReactNode;
}

const getColumnDef = ({
  id,
  label,
  getCell,
}: StaticColumnProps): ColumnDef<any> => {
  return {
    id,
    minSize: 240,
    header: label,
    meta: {
      label,
    },
    cell: ({ row }) => {
      const user = row?.original;

      return getCell(user);
    },
  };
};

export const getStaticContactColumns = (
  staticColumns: StaticColumnProps[] = [],
): ColumnDef<any>[] => {
  return staticColumns.map((column) => getColumnDef(column));
};

const enrichGroupableFromForms = (
  forms: FormTab[],
  contactType: AllowedContactType,
) => {
  return getGroupableFromForms(forms).map((component) => ({
    ...component,
    contactType,
  }));
};

export const getLabelByContactType = (contactType: AllowedContactType) => {
  return contactType === AllowedContactType.company
    ? "Company"
    : contactType === AllowedContactType.person
      ? "Person"
      : "Office";
};

export const getGroupableComponentsAsOptions = (
  formsByContactType: Record<AllowedContactType, FormTab[]>,
  contactType?: AllowedContactType,
) => {
  const availableOptions = {
    [AllowedContactType.office]: {
      label: getLabelByContactType(AllowedContactType.office),
      options: enrichGroupableFromForms(
        formsByContactType[AllowedContactType.office],
        AllowedContactType.office,
      ),
    },
    [AllowedContactType.person]: {
      label: getLabelByContactType(AllowedContactType.person),
      options: enrichGroupableFromForms(
        formsByContactType[AllowedContactType.person],
        AllowedContactType.person,
      ),
    },
    [AllowedContactType.company]: {
      label: getLabelByContactType(AllowedContactType.company),
      options: enrichGroupableFromForms(
        formsByContactType[AllowedContactType.company],
        AllowedContactType.company,
      ),
    },
  };

  if (contactType) {
    return [availableOptions[contactType]];
  }
  return [
    availableOptions[AllowedContactType.person],
    availableOptions[AllowedContactType.company],
  ];
};

export const getSearchableUserComponents = (
  formsByContactType: Record<AllowedContactType, FormTab[]>,
  contactType?: AllowedContactType,
) => {
  const components = contactType
    ? enrichGroupableFromForms(formsByContactType[contactType], contactType)
    : [
        ...enrichGroupableFromForms(
          formsByContactType[AllowedContactType.office],
          AllowedContactType.office,
        ),
        ...enrichGroupableFromForms(
          formsByContactType[AllowedContactType.person],
          AllowedContactType.person,
        ),
        ...enrichGroupableFromForms(
          formsByContactType[AllowedContactType.company],
          AllowedContactType.company,
        ),
      ];

  return getSearchComponents(components);
};

interface GetContactRequestArgs extends ContactSearchProps {
  autoSelect: boolean;
  isCollection: boolean;
  collectionResourceIds?: string[];
  groupByComponentIds?: string[];
}

export const getContactRequestArgs = ({
  autoSelect,
  isCollection,
  contactType,
  searchTerm,
  groupByComponentIds,
  match = {},
  collectionResourceIds = [],
}: GetContactRequestArgs) => {
  let args: any = {
    autoSelect,
    isCollection,
  };
  if (contactType) {
    args.type = contactType;
  }

  if (!isEmpty(searchTerm) || !isEmpty(groupByComponentIds)) {
    args.match = {
      ...match,
      isDeleted: false,
    };
    if (contactType === AllowedContactType.office) {
      args.match = {
        ...args.match,
        //isActive: true,
      };
    }
    if (isCollection) {
      args.match = {
        ...args.match,
        ...addMatchForCollection(collectionResourceIds),
      };
    }
    args = {
      ...args,
      searchTerm,
      groupByComponentIds,
    };

    return args;
  }

  if (contactType === AllowedContactType.office) {
    args.isActive = true;
  }
  if (isCollection) {
    args.ids = collectionResourceIds;
  }

  args = {
    ...args,
    isDeleted: false,
    limit: PaginationLimit.contacts,
  };

  return args;
};

interface GetUserComponentFiltersProps {
  userId: string;
  components: any[];
  company?: string | string[];
  userType: AllowedContactType;
}

export const getUserComponentFilters = ({
  userId,
  components,
  company,
  userType,
}: GetUserComponentFiltersProps) => {
  const filters: any[] = [];
  const userComponentIds = components
    .filter((c) =>
      [
        FormComponentTypes.SearchAndSelect,
        FormComponentTypes.LabelledContactInputs,
      ].includes(c.component),
    )
    .map((c) => c.id);

  userComponentIds.forEach((id) => {
    filters.push({
      [`formValues.${id}`]: {
        $elemMatch: {
          contactId: userId,
        },
      },
    });
  });

  if (userType === AllowedContactType.person && !isEmpty(company)) {
    const companyId = Array.isArray(company) ? company[0] : company;

    userComponentIds.forEach((id) => {
      filters.push({
        [`formValues.${id}`]: {
          $elemMatch: {
            contactId: companyId,
          },
        },
      });
    });
  }

  if (!isEmpty(filters)) {
    return {
      $or: filters,
    };
  }
};
