import {
  AllowedContactType,
  LogicalOperators,
  PageComponentProps,
} from "@amenda-types";
import { Control, useController } from "react-hook-form";
import { FC, useRef, useState } from "react";
import Select, { components } from "react-select";
import {
  customStyling,
  selectMenuOverflow,
  selectMenuOverflowProps,
  selectStylesControlOverrideMini,
} from "@amenda-styles/customStyling";
import {
  useGetKeywords,
  useSearchContacts,
  useSearchProjects,
  useSearchUsers,
} from "@amenda-domains/queries";

import AsyncSelect from "react-select/async";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { FormComponentTypes } from "@amenda-constants";
import Fuse from "fuse.js";
import { Link } from "./Link";
import { MiniSearchField } from "@amenda-components/SearchComponents";
import { XIcon } from "lucide-react";
import clsx from "clsx";
import { debounce } from "lodash";
import { defaultColoredOptions } from "./ColoredSelect";
import { getDefaultSearchFilterValues } from "./common";
import { getFlatOptions } from "@amenda-components/FormComponents/common";
import { getUserName } from "@amenda-components/Contacts/common";
import { processSearchFilters } from "@amenda-components/Shared/common";
import { useFormAutoSave } from "@amenda-components/PageBuilder";
import { useTranslation } from "react-i18next";

interface Option {
  value: string;
  label: string;
}

interface BaseProps {
  component: PageComponentProps;
  control: Control<any>;
}

interface Props {
  id: string;
  label: string;
  isMulti?: boolean;
  options: Option[];
  value?: any | any[];
  onChange: (selected: Option | Option[] | null) => void;
}

interface AsyncProps extends Omit<Props, "options"> {
  loading: boolean;
  defaultOptions?: any;
  loadOptions: (inputValue: string) => Promise<Option[]>;
}

const DropdownIndicator = (props: any) => (
  <components.DropdownIndicator {...props}>
    <ChevronDownIcon
      className={clsx("h-5 w-5 text-gray-400", {
        "rotate-180": props.selectProps.menuIsOpen,
      })}
      aria-hidden="true"
    />
  </components.DropdownIndicator>
);

const ValueContainer = ({ children, ...props }: any) => {
  const handleToggleMenu = () => {
    const isOpen = Boolean(props.selectProps.menuIsOpen);
    if (isOpen && props.selectProps?.onMenuClose) {
      props.selectProps?.onMenuClose();
    } else if (props.selectProps?.onMenuOpen) {
      props.selectProps?.onMenuOpen();
    }
  };

  return (
    <div onClick={handleToggleMenu}>
      <components.ValueContainer {...props}>
        <span className="text-gray-500">{props?.selectProps?.placeholder}</span>
      </components.ValueContainer>
    </div>
  );
};

const MenuList = (props: any) => {
  const inputValue = props.selectProps?.inputValue ?? "";
  const onInputChange = props.selectProps?.onInputChange;

  const handleClear = () => {
    if (onInputChange) {
      onInputChange("");
    }
  };

  const handleMenuClose = () => {
    if (props.selectProps?.onMenuClose) {
      props.selectProps?.onMenuClose();
    }
  };

  return (
    <div onBlur={handleMenuClose} onMouseLeave={handleMenuClose}>
      <components.MenuList {...props}>
        <div className="w-full">
          <MiniSearchField
            value={inputValue}
            placeholder="Suchen..."
            onChange={props.selectProps?.onInputChange}
            onClear={handleClear}
          />
        </div>
        {props.children}
      </components.MenuList>
    </div>
  );
};

const NavigationFilterSelect: FC<Props> = ({
  id,
  label,
  value,
  options = [],
  isMulti = false,
  onChange,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const handleChange = (value: any | any[] | null) => {
    onChange(value);
  };

  return (
    <div ref={ref}>
      <Select
        inputId={id}
        options={options}
        isMulti={isMulti}
        value={value}
        placeholder={t(label)}
        isClearable={false}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        components={{
          DropdownIndicator,
          MenuList,
          ValueContainer,
        }}
        onChange={handleChange}
        getOptionLabel={(option: Option) => t(option.label)}
        className={clsx(customStyling.select.containerClass, "w-max h-9")}
        styles={{
          ...customStyling.select.styleOverride,
          ...selectMenuOverflow(true, ref, {
            preventLineBreak: true,
          }),
          ...selectStylesControlOverrideMini,
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
        })}
        {...selectMenuOverflowProps(true)}
      />
    </div>
  );
};

const NavigationFilterAsyncSelect: FC<AsyncProps> = ({
  id,
  label,
  loading,
  value,
  defaultOptions,
  isMulti = false,
  loadOptions,
  onChange,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  const handleInputChange = debounce(
    (inputValue: string, callback: (options: Option[]) => void) => {
      if (defaultOptions || inputValue.length > 1) {
        loadOptions(inputValue).then(callback);
      }
    },
    300,
  );

  const handleChange = (value: any | any[] | null) => {
    onChange(value);
  };

  return (
    <div ref={ref}>
      <AsyncSelect
        inputId={id}
        cacheOptions
        value={value}
        isLoading={loading}
        defaultOptions={defaultOptions}
        loadOptions={handleInputChange}
        onChange={handleChange}
        isMulti={isMulti}
        placeholder={t(label)}
        components={{
          DropdownIndicator,
          MenuList,
          ValueContainer,
          LoadingIndicator: null,
        }}
        isClearable={false}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        className={clsx(customStyling.select.containerClass, "w-max h-9")}
        styles={{
          ...customStyling.select.styleOverride,
          ...selectMenuOverflow(true, ref, {
            preventLineBreak: true,
          }),
          ...selectStylesControlOverrideMini,
        }}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
        })}
        {...selectMenuOverflowProps(true)}
      />
    </div>
  );
};

export const NavigationFilterSearchField: FC<BaseProps> = ({
  component,
  control,
}) => {
  const { searchProjects } = useSearchProjects();
  const { getKeywords } = useGetKeywords();
  const { searchUsers } = useSearchUsers();
  const { searchContacts } = useSearchContacts();
  const [loading, setLoading] = useState(false);

  const id = component.id;
  const label = component?.properties?.label ?? "";
  const isMulti = true;
  const options =
    component?.component === FormComponentTypes.ColoredSelect
      ? defaultColoredOptions
      : (component?.properties?.options ?? []);

  const {
    field: { value, onChange },
  } = useController({
    control,
    name: id,
  });

  const values = value?.display?.options ?? [];

  const loadOptionsByComponentType: Record<string, any> = {
    [FormComponentTypes.SearchAndSelectProjects]: async (
      inputValue: string,
    ) => {
      setLoading(true);
      const projects = await searchProjects({
        searchTerm: inputValue,
        skipStorage: true,
      });
      setLoading(false);

      return [...(projects ?? [])].map((project: any) => ({
        value: project.id,
        label: project.name,
      }));
    },
    [FormComponentTypes.Keyword]: async (inputValue: string) => {
      setLoading(true);
      const keywords = await getKeywords({
        componentIds: [id],
        setKeywords: () => {},
      });
      setLoading(false);

      let availableKeywords = [...(keywords ?? [])];
      if (inputValue.length > 1) {
        const fuse = new Fuse(keywords, {
          includeScore: true,
          keys: ["name"],
          threshold: 0.3,
        });
        const results = fuse.search(inputValue);
        availableKeywords = results.map((res) => res.item);
      }

      return availableKeywords.map((keyword) => ({
        value: keyword.id,
        label: keyword.name,
      }));
    },
    [FormComponentTypes.SearchAndSelect]: async (inputValue: string) => {
      const search =
        component?.properties?.contactType === AllowedContactType.office
          ? searchUsers
          : searchContacts;

      setLoading(true);
      const users = await search({
        skipStorage: true,
        searchTerm: inputValue,
        callback: (users) => {
          return users.map((user) => ({
            value: user.id,
            label: user.name,
          }));
        },
      });
      setLoading(false);

      return [...(users ?? [])].map((u) => ({
        value: u.id,
        label: getUserName(u),
      }));
    },
  };

  const loadOptions = loadOptionsByComponentType[component.component as string];

  const handleChange = (options: Option | Option[] | null) => {
    let value = (
      Array.isArray(options) ? options.map((o) => o.value) : [options?.value]
    ).filter(Boolean);

    if (Array.isArray(options) && Boolean(component?.properties?.isNested)) {
      value = getFlatOptions(options).map((o) => o.value);
    }

    const processedFilter = processSearchFilters({
      value,
      component,
      other: {
        operation: LogicalOperators.OR,
        display: {
          options,
        },
      },
    });
    onChange(processedFilter);
  };

  if (
    [
      FormComponentTypes.Select,
      FormComponentTypes.MultiSelect,
      FormComponentTypes.Badges,
      FormComponentTypes.Checkbox,
      FormComponentTypes.RadioButton,
      FormComponentTypes.ColoredSelect,
    ].includes(component.component as FormComponentTypes)
  ) {
    return (
      <NavigationFilterSelect
        id={id}
        label={label}
        options={options}
        isMulti={isMulti}
        value={values}
        onChange={handleChange}
      />
    );
  }
  return (
    <NavigationFilterAsyncSelect
      id={id}
      label={label}
      isMulti={isMulti}
      loading={loading}
      loadOptions={loadOptions}
      value={values}
      defaultOptions={component?.component === FormComponentTypes.Keyword}
      onChange={handleChange}
    />
  );
};

interface RemoveNavigationFilterButtonProps {
  id: string;
  value: string;
  sidebarFilters: Record<string, any>;
}

interface ClearFilterButtonProps {
  show: boolean;
  handleClearSearch: () => {};
}

export const ClearFiltersButton: FC<ClearFilterButtonProps> = ({
  show,
  handleClearSearch,
}) => {
  const { t } = useTranslation();
  const { reset, getValues } = useFormAutoSave();

  const handleClick = async () => {
    const resetValue = getDefaultSearchFilterValues(getValues?.(), {});
    reset?.(resetValue);
    await handleClearSearch();
  };

  if (!show) return null;
  return (
    <Link className="flex items-center gap-x-1" onClick={handleClick}>
      <XIcon className="h-4 w-4 stroke-2" />
      <span className="leading-none">{t("Clear all")}</span>
    </Link>
  );
};

export const RemoveNavigationFilterButton: FC<
  RemoveNavigationFilterButtonProps
> = ({ id, value, sidebarFilters }) => {
  const { setValue } = useFormAutoSave();

  const handleClick = () => {
    const selectedFilters = sidebarFilters?.[id] ?? {};
    const options = selectedFilters.display?.options;
    const updatedSidebarFilters = {
      ...selectedFilters,
      value: Array.isArray(options)
        ? options.filter((o) => o.value !== value).map((o) => o.value)
        : undefined,
      display: {
        options: Array.isArray(options)
          ? options.filter((o) => o.value !== value)
          : [],
      },
    };

    if (setValue) {
      setValue(id, updatedSidebarFilters, {
        shouldDirty: true,
      });
    }
  };

  return (
    <button className="cursor-pointer" type="button" onClick={handleClick}>
      <XIcon className="ml-1 w-4 h-4" />
    </button>
  );
};
