import {
  AllowedCollectionType,
  AvailableNotificationTypes,
} from "@amenda-types";
import {
  COLLECTION_FRAGMENT,
  FORM_FRAGMENT,
} from "@amenda-domains/fragments/forms";
import { gql, useMutation } from "urql";

import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { transformFormComponents } from "@amenda-utils";
import { useAppStore } from "./app";
import { useProjectStore } from "./projects";

type State = {
  collectionsByType: Record<AllowedCollectionType, any[]>;
  selectedCollectionByType: Record<AllowedCollectionType, any>;
};

type Actions = {
  clearSelectedCollectionByType: (type: AllowedCollectionType) => void;
  setSelectedCollectionByType: (collection: any) => void;
  setCollectionsByType: (
    collections: any[],
    collectionType?: AllowedCollectionType,
  ) => void;
  updateCollection: (collection: any) => void;
};

export const useFormStore = create(
  immer<State & Actions>((set, get) => ({
    collectionsByType: {
      [AllowedCollectionType.Attachments]: [],
      [AllowedCollectionType.Projects]: [],
      [AllowedCollectionType.Users]: [],
      [AllowedCollectionType.Widgets]: [],
      [AllowedCollectionType.Materials]: [],
      [AllowedCollectionType.Contacts]: [],
      [AllowedCollectionType.ConstructionDetails]: [],
    },
    selectedCollectionByType: {
      [AllowedCollectionType.Attachments]: {},
      [AllowedCollectionType.Projects]: {},
      [AllowedCollectionType.Users]: {},
      [AllowedCollectionType.Widgets]: {},
      [AllowedCollectionType.Materials]: {},
      [AllowedCollectionType.Contacts]: {},
      [AllowedCollectionType.ConstructionDetails]: {},
    },
    updateCollection: (collection) =>
      set((state) => {
        const collectionType =
          collection.collectionType as AllowedCollectionType;
        const collections = get().collectionsByType[collectionType];
        const selectedCollection =
          get().selectedCollectionByType[collectionType];

        if (collection.id === selectedCollection?.id) {
          state.selectedCollectionByType[collectionType] = selectedCollection;
        }
        state.collectionsByType[collectionType] = collection.isDeleted
          ? collections.filter((c) => c.id !== collection.id)
          : collections.map((c) => (c.id === collection.id ? collection : c));
      }),
    clearSelectedCollectionByType: (type) =>
      set((state) => {
        state.selectedCollectionByType[type] = {};
      }),
    setSelectedCollectionByType: (collection) =>
      set((state) => {
        const type = collection.collectionType as AllowedCollectionType;
        state.selectedCollectionByType[type] = collection;
      }),
    setCollectionsByType: (collections, type) =>
      set((state) => {
        const collectionType =
          type || (collections[0].collectionType as AllowedCollectionType);

        state.collectionsByType[collectionType] = collections;
      }),
  })),
);

const CREATE_COLLECTION = gql`
  ${COLLECTION_FRAGMENT}
  mutation CreateCollection($input: CollectionInput!) {
    createCollection(input: $input) {
      ...CollectionFragment
    }
  }
`;

export const useCreateCollection = () => {
  const [result, callCreateCollection] = useMutation(CREATE_COLLECTION);

  const createCollection = async (variables: Record<string, any>) => {
    return callCreateCollection(variables).then(({ data }) => {
      return data?.createCollection;
    });
  };

  return {
    createCollection,
    loading: result.fetching,
  };
};

const UPDATE_COLLECTION = gql`
  ${COLLECTION_FRAGMENT}
  mutation UpdateCollection($input: CollectionUpdateInput!) {
    updateCollection(input: $input) {
      ...CollectionFragment
    }
  }
`;

export const useUpdateCollection = () => {
  const [result, callUpdateCollection] = useMutation(UPDATE_COLLECTION);
  const updateCollectionInStore = useFormStore(
    (state) => state.updateCollection,
  );

  const updateCollection = async (variables: Record<string, any>) => {
    return callUpdateCollection(variables).then(({ data }) => {
      if (data?.updateCollection) {
        updateCollectionInStore(data.updateCollection);
      }
    });
  };

  return {
    updateCollection,
    loading: result.fetching,
  };
};

const UPDATE_COLLECTIONS_RESOURCE_IDS = gql`
  mutation UpdateCollectionsResourceIds(
    $input: CollectionsResourceIdsUpdateInput!
  ) {
    updateCollectionsResourceIds(input: $input)
  }
`;

export const useUpdateCollectionsResourceIds = () => {
  const [result, callUpdateCollectionsResourceIds] = useMutation(
    UPDATE_COLLECTIONS_RESOURCE_IDS,
  );
  const showNotification = useAppStore((state) => state.showNotification);

  const updateCollectionsResourceIds = async (
    variables: Record<string, any>,
  ) => {
    return callUpdateCollectionsResourceIds(variables).then(({ data }) => {
      if (data?.updateCollectionsResourceIds) {
        showNotification(
          AvailableNotificationTypes.Success,
          "Resources added to collections",
        );
      }
    });
  };

  return {
    updateCollectionsResourceIds,
    loading: result.fetching,
  };
};

const UPDATE_FORM = gql`
  ${FORM_FRAGMENT}
  mutation UpdateForm(
    $id: ID
    $components: Object
    $isDeleted: Boolean
    $name: String
    $order: Int
    $properties: Object
  ) {
    updateForm(
      input: {
        _id: $id
        components: $components
        isDeleted: $isDeleted
        name: $name
        order: $order
        properties: $properties
      }
    ) {
      ...FormFragment
    }
  }
`;

const CREATE_FORM = gql`
  ${FORM_FRAGMENT}
  mutation CreateForm(
    $components: Object
    $name: String!
    $order: Int
    $category: String!
  ) {
    createForm(
      input: {
        components: $components
        name: $name
        order: $order
        category: $category
      }
    ) {
      ...FormFragment
    }
  }
`;

export const useUpdateForm = () => {
  const [result, callUpdateForm] = useMutation(UPDATE_FORM);
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const selectedForm = useAppStore(
    (state) => state.formBuilderState?.selectedForm,
  );
  const upsertOrDeleteForm = useProjectStore(
    (state) => state.upsertOrDeleteForm,
  );

  const updateForm = async (variables: Record<string, any>) => {
    return callUpdateForm(variables).then(({ data }) => {
      const form = data?.updateForm;

      if (form && selectedForm && form.id === selectedForm.id) {
        const updatedForm = form.isDeleted
          ? {}
          : {
              ...form,
              components: transformFormComponents(form.components, form.id),
            };

        upsertOrDeleteForm(form);
        updateFormBuilderState("selectedForm", updatedForm);
      }
    });
  };

  return {
    updateForm,
    loading: result.fetching,
  };
};

export const useCreateForm = () => {
  const [result, callUpsertForm] = useMutation(CREATE_FORM);
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const upsertOrDeleteForm = useProjectStore(
    (state) => state.upsertOrDeleteForm,
  );

  const createForm = async (variables: Record<string, any>) => {
    return callUpsertForm(variables).then(({ data }) => {
      const form = data?.createForm;

      if (form) {
        upsertOrDeleteForm(form);
        updateFormBuilderState("selectedForm", {
          ...form,
          components: transformFormComponents(form.components, form.id),
        });
      }
      return form;
    });
  };

  return {
    createForm,
    loading: result.fetching,
  };
};

const DELETE_FORM_COMPONENTS = gql`
  ${FORM_FRAGMENT}
  mutation DeleteFormComponents($formId: ID!, $componentIds: [ID!]!) {
    deleteFormComponents(
      input: { formId: $formId, componentIds: $componentIds }
    ) {
      ...FormFragment
    }
  }
`;

export const useDeleteFormComponents = () => {
  const [result, callDeleteFormComponents] = useMutation(
    DELETE_FORM_COMPONENTS,
  );
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const selectedForm = useAppStore(
    (state) => state.formBuilderState?.selectedForm,
  );
  const upsertOrDeleteForm = useProjectStore(
    (state) => state.upsertOrDeleteForm,
  );

  const deleteFormComponents = async (variables: Record<string, any>) => {
    return callDeleteFormComponents(variables).then(({ data }) => {
      const form = data?.deleteFormComponents;

      if (form && selectedForm && form.id === selectedForm.id) {
        upsertOrDeleteForm(form);
        updateFormBuilderState("selectedForm", {
          ...form,
          components: transformFormComponents(form.components, form.id),
        });
      }
    });
  };

  return {
    deleteFormComponents,
    loading: result.fetching,
  };
};

const UPDATE_MULTIPLE_FORMS = gql`
  ${FORM_FRAGMENT}
  mutation UpdateMultipleForms($forms: [FormUpdateInput!]!) {
    updateMultipleForms(input: { forms: $forms }) {
      ...FormFragment
    }
  }
`;

export const useUpdateMultipleForms = () => {
  const [result, callUpdateMultipleForms] = useMutation(UPDATE_MULTIPLE_FORMS);
  const updateFormBuilderState = useAppStore(
    (state) => state.updateFormBuilderState,
  );
  const selectedForm = useAppStore(
    (state) => state.formBuilderState?.selectedForm,
  );
  const upsertOrDeleteForm = useProjectStore(
    (state) => state.upsertOrDeleteForm,
  );

  const updateMultipleForms = async (variables: Record<string, any>) => {
    return callUpdateMultipleForms(variables).then(({ data }) => {
      const forms = data?.updateMultipleForms ?? [];
      const updatedSelectedForm =
        selectedForm?.id && forms.find((f: any) => f.id === selectedForm.id);

      if (updatedSelectedForm) {
        updateFormBuilderState("selectedForm", {
          ...updatedSelectedForm,
          components: transformFormComponents(
            updatedSelectedForm.components,
            updatedSelectedForm.id,
          ),
        });
      }
      forms.forEach((form: any) => {
        upsertOrDeleteForm(form);
      });
    });
  };

  return {
    updateMultipleForms,
    loading: result.fetching,
  };
};
