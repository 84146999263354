import {
  ComponentTreeRenderer,
  FormAutoSaveSubmitProps,
  FormAutoSaveWrapper,
} from "@amenda-components/PageBuilder";
import {
  GeneralPermissionKeys,
  PermissionComponents,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import {
  getValidationWithPermissions,
  groupComponentsByParent,
} from "@amenda-utils";
import { isEmpty, isNil } from "lodash";
import { transformFormToUsers, transformUsersToForm } from "./common";
import {
  useCreateContact,
  useSettingsStore,
  useUpdateContact,
  useUpdateUser,
  useUsersStore,
} from "@amenda-domains/mutations";

import { AllowedContactType } from "@amenda-types";
import { FC } from "react";
import { PermissionComponentKey } from "@amenda-constants";
import { processFormPermissions } from "@amenda-components/Shared/common";

interface Props {
  user: Record<string, any>;
  contactType: AllowedContactType;
  components: any[];
}

export const ContactsForm: FC<Props> = ({ user, contactType, components }) => {
  const { createContact } = useCreateContact();
  const { updateContact } = useUpdateContact();
  const { updateUser } = useUpdateUser();
  const setIsSubmitting = useUsersStore((state) => state.setIsSubmitting);
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );

  const values = transformUsersToForm(user);
  const validationSchema = getValidationWithPermissions(
    components,
    processFormPermissions(permissions),
  );
  const formComponentTree = groupComponentsByParent(
    components,
    processFormPermissions(permissions),
  );
  const contactsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Contacts,
  );
  const defaultShareType =
    contactsGeneralPermissions?.[PermissionComponentKey]?.[
      PermissionComponents.GeneralAccess
    ];

  const onSubmit = async ({
    resourceId,
    data,
    dirtyData,
  }: FormAutoSaveSubmitProps) => {
    let input = transformFormToUsers({
      defaultShareType,
      form: dirtyData,
      id: resourceId,
      type: contactType,
      uid: user?.uid,
      existingData: data,
    });
    const handleSave = resourceId
      ? contactType === AllowedContactType.office
        ? updateUser
        : updateContact
      : createContact;

    if (isEmpty(dirtyData)) {
      return;
    }

    setIsSubmitting(true);
    await handleSave({
      input,
    });
    setIsSubmitting(false);
  };

  return (
    <FormAutoSaveWrapper
      key={user?.id ?? "new-user"}
      resourceId={user?.id}
      inputSchema={validationSchema}
      formComponentTree={formComponentTree}
      values={values}
      onSubmit={onSubmit}
    />
  );
};

export const ReadOnlyContactsForm: FC<Props> = ({ user, components }) => {
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const modifiedComponents = components
    .filter((c) => isNil(c.headerProperties))
    .filter(
      (c) =>
        !["company", "firstName", "lastName", "companyName"].includes(c.id),
    ); //TODO: move this to the json

  const formComponentTree = groupComponentsByParent(
    modifiedComponents,
    processFormPermissions(permissions),
  );
  const data = transformUsersToForm(user);

  return (
    <ComponentTreeRenderer
      config={formComponentTree}
      readOnly={true}
      globalProps={{
        data,
        hasMenuOverflow: true,
      }}
    />
  );
};
