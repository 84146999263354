import { gql } from "urql";

export const TIMELINE_ACTIVITY_FRAGMENT = gql`
  fragment TimelineActivityFragment on TimelineActivity {
    id: _id
    activity
    body
    createdById
    date
    isDeleted
    resourceId
    title
    type
  }
`;

export const TIMELINE_ACTIVITY_SUBSCRIPTION = gql`
  fragment TimelineActivitySubFragment on TimelineActivitySubscription {
    id: _id
    isDeleted
  }
`;
