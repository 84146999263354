import { ChangeEvent, FC, ReactNode } from "react";

import { IconButtonBase } from "@amenda-components/App";
import { SearchIcon } from "lucide-react";
import { XIcon } from "lucide-react";
import clsx from "clsx";
import { inputFieldTheme } from "@amenda-styles/theme";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

interface SearchFieldProps {
  value: string;
  className?: string;
  placeholder?: string;
  CloseIcon?: ReactNode;
  onChange: (value: string) => void;
  onClear?: () => void;
}

export const MiniSearchField: FC<SearchFieldProps> = ({
  value,
  CloseIcon,
  placeholder,
  className = "border-b border-gray-300 bg-white",
  onChange,
  onClear,
}) => {
  const { t } = useTranslation();
  const { inputCss } = inputFieldTheme();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value);
  };

  return (
    <div className={clsx("flex relative", className)}>
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <SearchIcon className="h-4 w-4 text-gray-700" aria-hidden="true" />
      </div>
      <input
        autoFocus
        className={inputCss({
          className: clsx("border-none py-2 pl-9 focus:ring-0 bg-[inherit]", {
            "pr-4": !CloseIcon,
            "pr-8": !!CloseIcon,
          }),
        })}
        type="text"
        value={value ?? ""}
        autoComplete="off"
        tabIndex={0}
        placeholder={placeholder && t(placeholder)}
        onChange={handleChange}
      />
      {onClear && !isEmpty(value) && (
        <div className="absolute inset-y-0 right-0 pr-2 flex items-center">
          <IconButtonBase label="Close Search" onClick={onClear}>
            <XIcon className="h-3 w-3" />
          </IconButtonBase>
        </div>
      )}
    </div>
  );
};
