import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { HelperMessage, Spinner } from "@amenda-components/App";
import { decodeToken, getQueryParams } from "@amenda-utils";
import { signIn, useAppStore } from "@amenda-domains/mutations";
import { useLocation, useNavigate } from "react-router-dom";

import { AvailableNotificationTypes } from "@amenda-types";
import { FC } from "react";
import { LoginSchema } from "@amenda-constants";
import { TextField } from "@amenda-components/FormComponents";
import { useGetTenantId } from "@amenda-domains/queries";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";

interface LoginInput {
  email: string;
  password: string;
}

export const LoginWithTokenForm: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = getQueryParams(location.search);

  const { t } = useTranslation();
  const { getTenantId, loading } = useGetTenantId();
  const showNotification = useAppStore((state) => state.showNotification);

  const token: string = query.token;
  const decoded = decodeToken(token || "");

  const { control, handleSubmit } = useForm<LoginInput>({
    values: { password: "", email: decoded.email || "" },
    resolver: yupResolver(LoginSchema),
  });

  const onSubmit: SubmitHandler<LoginInput> = async (form) => {
    try {
      const tenantId = await getTenantId(form.email);
      await signIn({
        ...form,
        tenantId,
      });
      navigate("/projekte");
    } catch (error: any) {
      showNotification(
        AvailableNotificationTypes.Error,
        error.message || "Something went wrong",
      );
    }
  };

  if (!token) {
    return (
      <HelperMessage message="Token is invalid, click the link in your email." />
    );
  }
  return (
    <>
      <div className="font-apercu">
        Wir freuen uns, dass du dich für amenda registriert hast. amenda ist
        eine büroweite Projekt- und Medien-Datenbank. Melde Dich bitte mit
        deiner E-Mail Adresse und Passwort an.
      </div>
      <form className="space-y-6 pt-6" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  id="email"
                  label="E-Mail"
                  error={error?.message}
                  value={value}
                  type="email"
                  onChange={onChange}
                />
              );
            }}
          />
        </div>

        <div className="space-y-1">
          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <TextField
                  id="password"
                  label="Passwort"
                  error={error?.message}
                  value={value}
                  type="password"
                  autoComplete="current-password"
                  onChange={onChange}
                />
              );
            }}
          />
        </div>
        <div>
          <button type="submit" className="amenda-button">
            {loading && <Spinner spinnerSize="xs" variant="primary" />}
            {t("Sign in")}
          </button>
        </div>
      </form>
    </>
  );
};
