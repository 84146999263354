import {
  AvailablePermissions,
  ComponentValidationType,
  GeneralPermissionTypes,
  NestedPageComponentProps,
  PageComponentProps,
  ProjectView,
  SimilaritySearchMatchTypes,
  availableLayouts,
} from "@amenda-types";
import {
  getComponentsById,
  getValidationSchema,
  safeParse,
  sanitizeData,
  sanitizeNestedData,
} from "@amenda-utils";
import { isEmpty, isNil, sortBy } from "lodash";

import DayJs from "dayjs";
import { FormComponentTypes } from "@amenda-constants";
import { TransformedCostEstimateValues } from "@amenda-domains/mutations";
import { useCallback } from "react";

export const formatTemplateValues = (values: any) => {
  let formattedValues = {};
  Object.keys(values).forEach((key) => {
    let properties = {};
    const value = values[key];
    Object.keys(value).forEach((key) => {
      if (value[key]) {
        properties = {
          ...properties,
          [key === "imageSrc" ? "src" : key]: value[key],
        };
      }
    });
    formattedValues = {
      ...formattedValues,
      [key]: {
        id: key,
        properties,
      },
    };
  });
  return formattedValues;
};

export const transformProjectToForm = (project: Record<string, any> = {}) => {
  const { formValues = {} } = project;

  return sanitizeData(formValues);
};

export const transformFormToProject = ({
  resourceId,
  defaultShareType,
  form = {},
}: {
  form?: Record<string, any>;
  resourceId?: string;
  defaultShareType?: string;
}) => {
  const { name, address, number, regionId, ...rest } = form;
  const input: any = {
    _id: resourceId,
    name,
    address,
    number,
    regionId,
    formValues: {
      ...rest,
      name,
      address,
      number,
      regionId,
    },
  };
  if (defaultShareType && !resourceId) {
    input.share = {
      type: defaultShareType,
    };
  }

  return sanitizeNestedData(input);
};

export const processAllFormPermissions = (
  allPermissions: Record<string, any>,
  components: PageComponentProps[],
) => {
  return components.filter((component) => {
    const permission =
      allPermissions?.[String(component.formId)]?.[component.id];

    return (
      isNil(permission) ||
      [AvailablePermissions.Edit, AvailablePermissions.Read].includes(
        permission,
      )
    );
  });
};

export enum ProjectTabRoutes {
  CostGroups = "cost_groups",
  Attachments = "specific_attachments",
  Designs = "project_designs",
  Participants = "project_participants",
  Activity = "activity",
  ConstructionDetails = "construction_details",
}

export const staticProjectLabels = {
  costGroups: {
    label: "Cost groups",
    value: ProjectTabRoutes.CostGroups,
  },
  constructionDetails: {
    label: "Construction Details",
    value: ProjectTabRoutes.ConstructionDetails,
  },
  attachments: {
    label: "Project Specific Attachments",
    value: ProjectTabRoutes.Attachments,
  },
  designs: {
    label: "Designs",
    value: ProjectTabRoutes.Designs,
  },
  participants: {
    label: "Project Participants",
    value: ProjectTabRoutes.Participants,
  },
  activity: {
    label: "Activity",
    value: ProjectTabRoutes.Activity,
  },
};

export const getStaticProjectTabs = (generalProjectPermissions: any) => {
  const options = Object.keys(staticProjectLabels)
    .map((key) => ({
      ...staticProjectLabels[key as keyof typeof staticProjectLabels],
    }))
    .filter(({ value }) => value !== staticProjectLabels.participants.value);
  const permissions = generalProjectPermissions ?? {};

  return options.filter(({ value }) => {
    if (value === staticProjectLabels.activity.value) {
      return Boolean(permissions[GeneralPermissionTypes.Activity]);
    }
    return true;
  });
};

export const SimilarityConfigKey = "SimilarityConfig";

export const generateSearchComponentTree = (
  components: PageComponentProps[],
  parentComponent: PageComponentProps,
) => {
  const updatedComponents = components
    .filter((c) => {
      return c?.component
        ? ![
            FormComponentTypes.LabelledInput,
            FormComponentTypes.LabelledContactInputs,
            FormComponentTypes.SelectCostGroups,
          ].includes(c.component as FormComponentTypes)
        : true;
    })
    .map((c) => {
      if (!c?.component) return c;
      if (
        [FormComponentTypes.Checkbox, FormComponentTypes.Badges].includes(
          c.component as FormComponentTypes,
        )
      ) {
        return {
          ...c,
          component: FormComponentTypes.MultiSelect,
        };
      } else if (c.component === FormComponentTypes.RadioButton) {
        return {
          ...c,
          component: FormComponentTypes.Select,
        };
      }
      return c;
    })
    .map((c) => ({
      ...c,
      layout: availableLayouts.halfColumn,
    }));

  return {
    ...parentComponent,
    components: updatedComponents,
    layout: availableLayouts.fullColumnGrid,
  };
};

const addValidations = (component: any, validation: Record<string, any>) => {
  return {
    ...component,
    validation: {
      ...component.validation,
      properties: {
        ...component.validation.properties,
        ...validation,
      },
    },
  };
};

export const generateSearchValidation = (components: PageComponentProps[]) => {
  const updatedComponents = components.map((component) => {
    if (!component.validation?.properties) return component;
    if (component.validation.type === ComponentValidationType.Array) {
      return addValidations(component, {
        min: 1,
        required: true,
      });
    }
    return addValidations(component, {
      required: true,
    });
  });
  return getValidationSchema(updatedComponents);
};

export const getSimilaritySearchComponents = (
  permissionsByForm: Record<string, any>,
  projectFormComponents: PageComponentProps[],
) => {
  const parentComponent =
    projectFormComponents.find((c) => isNil(c.parentId)) ||
    projectFormComponents[0];

  const components = projectFormComponents.filter((c) => {
    if (!c.formId) return true;

    const permission = permissionsByForm[c.formId]?.[c.id];
    return (
      !isNil(c.component) &&
      FormComponentTypes.SearchAndSelect !== c.component &&
      permission !== AvailablePermissions.Restricted
    );
  });

  return {
    components,
    parentComponent,
  };
};

export const getSimilaritySearchConfigComponents = (
  permissions: Record<string, any>,
  components: PageComponentProps[],
) => {
  return components.filter((c) => {
    if (!c.formId) return true;

    const permission = permissions?.[c.formId]?.[c.id];
    return (
      FormComponentTypes.SearchAndSelect !== c.component &&
      permission !== AvailablePermissions.Restricted
    );
  });
};

export const depthOptions = [
  {
    value: "1",
    label: "Depth 1",
  },
  {
    value: "2",
    label: "Depth 2",
  },
  {
    value: "3",
    label: "Depth 3",
  },
];

export enum CostEstimateColumns {
  Hits = "hits",
  UnitPrice = "unitPrice",
  AveragePrice = "averagePrice",
  TotalCost = "totalCost",
}

export const costEstimateHeaders = [
  {
    id: CostEstimateColumns.UnitPrice,
    label: "unit price",
  },
  {
    id: CostEstimateColumns.AveragePrice,
    label: "average price",
  },
  {
    id: CostEstimateColumns.TotalCost,
    label: "% of total cost",
  },
  {
    id: CostEstimateColumns.Hits,
    label: "hits",
  },
];

export enum QuantityEstimateColumns {
  Quantity = "quantity",
  Placeholder = "placeholder",
}

export const quantityEstimateHeaders = [
  {
    id: QuantityEstimateColumns.Quantity,
    label: "Quantity",
  },
  {
    id: QuantityEstimateColumns.Placeholder,
    label: "",
  },
];

interface GetAllCostGroupsProps {
  depth: number;
  selectedCostGroups: string[];
  groupedCostGroupComponents?: NestedPageComponentProps[];
}

interface ProcessConsiderationCostGroupsProps {
  estimationMode: boolean;
  componentByCode: Record<string, any>;
  considerationCostGroups: number[];
  costGroupQuantityComponents?: any[];
}

interface GetConsiderationCostGroups
  extends GetAllCostGroupsProps,
    Omit<ProcessConsiderationCostGroupsProps, "considerationCostGroups"> {
  componentsByCode: Record<string, number | null>;
}

export const isBgfComponent = (c: PageComponentProps) => {
  return c?.properties?.label?.toLowerCase().includes("bgf");
};

const getAllCostGroups = ({
  depth,
  selectedCostGroups,
  groupedCostGroupComponents = [],
}: GetAllCostGroupsProps) => {
  let trips = depth;
  let stack = groupedCostGroupComponents?.filter((c) =>
    selectedCostGroups?.includes(c.id),
  );
  const costGroups: number[] = [];

  while (trips > 0) {
    const costGroupsIds = stack
      .filter((c) => !Number.isNaN(c.properties?.code))
      .map((c) => Number(c.properties?.code));
    const childComponents: NestedPageComponentProps[] = [];

    trips -= 1;
    costGroups.push(...costGroupsIds);
    stack.forEach((c) => {
      childComponents.push(...(c.components || []));
    });

    stack = childComponents;
  }

  return { costGroups };
};

const getCalculatedCostGroups = (
  costGroups: number[],
  componentsByCode: Record<string, number | null>,
) => {
  const inferredCalcCostGroups: number[] = [];

  costGroups.forEach((costGroup) => {
    const parentCostGroup = componentsByCode[String(costGroup)];
    if (parentCostGroup && !inferredCalcCostGroups.includes(parentCostGroup)) {
      inferredCalcCostGroups.push(parentCostGroup);
    }
  });

  const considerationCostGroups = costGroups.filter(
    (costGroup) => !inferredCalcCostGroups.includes(costGroup),
  );

  return { considerationCostGroups, inferredCalcCostGroups };
};

const processConsiderationCostGroups = ({
  estimationMode,
  componentByCode,
  considerationCostGroups,
  costGroupQuantityComponents = [],
}: ProcessConsiderationCostGroupsProps) => {
  if (!Boolean(estimationMode)) {
    const component = costGroupQuantityComponents.find(isBgfComponent);
    return {
      keys: considerationCostGroups,
      referenceQuantity: component?.id,
    };
  }

  return considerationCostGroups.map((key) => {
    const component = componentByCode[String(key)];
    return {
      key,
      referenceQuantity: component?.properties?.referenceQuantity,
    };
  });
};

export const getConsiderationCostGroup = ({
  depth,
  componentsByCode,
  selectedCostGroups,
  groupedCostGroupComponents,
  ...rest
}: GetConsiderationCostGroups) => {
  const { costGroups } = getAllCostGroups({
    depth,
    groupedCostGroupComponents,
    selectedCostGroups,
  });
  const { inferredCalcCostGroups, ...props } = getCalculatedCostGroups(
    costGroups,
    componentsByCode,
  );
  const considerationCostGroups = processConsiderationCostGroups({
    ...rest,
    considerationCostGroups: props.considerationCostGroups,
  });

  return { considerationCostGroups, inferredCalcCostGroups };
};

export const getComponentsByCode = (components: any[]) => {
  const componentsByCode: Record<string, number | null> = {};
  const componentsById = getComponentsById(components);

  components.forEach((component) => {
    if (component.properties?.code) {
      componentsByCode[component.properties.code] = component?.parentId
        ? componentsById[component?.parentId]?.properties?.code
        : null;
    }
  });

  return { componentsByCode };
};

export const useCheckSelectedCostGroups = ({
  stringifiedComponentsById,
  depth = 0,
  selectedCostGroups = [],
}: {
  depth?: number;
  selectedCostGroups?: string[];
  stringifiedComponentsById: string;
}) => {
  return useCallback(
    (componentId: string) => {
      const selectedCostGroupIds: string[] = [...selectedCostGroups];
      let trips = depth - 1;
      let stack: NestedPageComponentProps[] = [];
      const componentsById = safeParse(stringifiedComponentsById);

      selectedCostGroups.forEach((costGroupId) => {
        const component = componentsById[costGroupId];
        if (component) {
          stack.push(...(component.components || []));
        }
      });

      while (trips > 0) {
        const childComponents: NestedPageComponentProps[] = [];
        stack.forEach((c) => {
          selectedCostGroupIds.push(c.id);
          childComponents.push(...(c.components || []));
        });

        trips -= 1;
        stack = childComponents;
      }

      return selectedCostGroupIds.includes(componentId);
    },
    [depth, stringifiedComponentsById, selectedCostGroups],
  );
};

export const sortSimilaritySearchOptions =
  (selectedComponentsIds: string[]) => (a: any, b: any) => {
    const isSelectedA = selectedComponentsIds.includes(a.value);
    const isSelectedB = selectedComponentsIds.includes(b.value);
    if (isSelectedA && !isSelectedB) {
      return -1; // a comes before b
    } else if (!isSelectedA && isSelectedB) {
      return 1; // b comes before a
    }
    return 0; // no change in order
  };

export const getCostEstimateOptions = (
  permissions: Record<GeneralPermissionTypes, boolean>,
) => {
  return [
    {
      label: "New Project",
      value: "new",
    },
    {
      label: "Existing Project",
      value: "existing",
    },
  ].map((option) => {
    if (option.value === "new") {
      return {
        ...option,
        properties: {
          disabled: !permissions[GeneralPermissionTypes.Create],
        },
      };
    }
    return {
      ...option,
      properties: {
        disabled: !permissions[GeneralPermissionTypes.Edit],
      },
    };
  });
};

export const groupContactsById = (contacts: any[]) => {
  const contactsById: Record<string, any> = {};

  contacts.forEach((contact) => {
    contactsById[contact.id] = contact;
  });

  return { contactsById };
};

export const isMapOrTableView = (projectView: ProjectView) =>
  [ProjectView.Table, ProjectView.Map].includes(projectView);

export const convertEstimatesToCostGroupData = ({
  considerationValue,
  componentByCode,
  costEstimateValues,
  referenceQuantity,
}: {
  referenceQuantity: string;
  considerationValue: number;
  componentByCode: Record<string, any>;
  costEstimateValues: Record<string, TransformedCostEstimateValues>;
}) => {
  const data: Record<"values" | "quantities", any> = {
    quantities: {
      [referenceQuantity]: considerationValue,
    },
    values: [],
  };

  Object.keys(costEstimateValues).forEach((key) => {
    const component = componentByCode[key];
    const { costGroupAverage } = costEstimateValues[key];

    if (costGroupAverage) {
      data.values.push({
        key: Number(key),
        componentId: component.id,
        totalCost: costGroupAverage,
      });
    }
  });

  return data;
};

const addWeightsToSimilarityValues = (
  keys: string[],
  values: Record<string, any>,
) => {
  const similarityValues: any[] = [];

  keys.forEach((key) => {
    const value = values[key];
    const weightConfigKey = `${key}${SimilarityConfigKey}`;
    const weight =
      values[weightConfigKey]?.weight ?? SimilaritySearchDefaultValues.weight;
    const matchType =
      values[weightConfigKey]?.matchType ?? SimilaritySearchMatchTypes.Similar;

    similarityValues.push({
      value,
      matchType,
      componentId: key,
      weight: weight / 100,
    });
  });

  return { similarityValues };
};

export const transformSimilaritySearchData = (
  values: Record<string, any> = {},
  availableComponentIds: string[] = [],
) => {
  const keys = Object.keys(sanitizeData(values)).filter((key) =>
    availableComponentIds.includes(key),
  );
  const { similarityValues } = addWeightsToSimilarityValues(keys, values);

  return similarityValues;
};

export const transformSimilaritySearchToFormValues = (values: any[] = []) => {
  const formValues: Record<string, any> = {};

  values.forEach(({ value, weight, matchType, componentId }) => {
    const key = `${componentId}${SimilarityConfigKey}`;

    formValues[componentId] = value;
    formValues[key] = {
      matchType,
      weight: weight * 100,
    };
  });

  return formValues;
};

export const transformCostEstimateValues = (
  costEstimationModalValues: Record<string, any>,
) => {
  const { depth, ...rest } = costEstimationModalValues;
  const depths = isFinite(depth) ? [String(depth)] : [];

  return { ...rest, depth: depths };
};

export const getProjectArgs = ({
  collectionProjectIds,
  similarProjectIds,
  visibleColumns,
  isCollection = false,
}: {
  collectionProjectIds?: string[];
  isCollection?: boolean;
  similarProjectIds: string[];
  visibleColumns?: Record<string, boolean>;
}) => {
  const selectProjectFormValueFields: Record<string, number> = {};

  if (!!visibleColumns) {
    Object.keys(visibleColumns).forEach((key) => {
      if (!visibleColumns[key]) return;
      selectProjectFormValueFields[key] = 1;
    });
  }

  const args: any = {
    isCollection,
    selectProjectFormValueFields,
  };

  if (isCollection && !isEmpty(collectionProjectIds)) {
    args.ids = collectionProjectIds;
  }
  if (!isEmpty(similarProjectIds)) {
    args.ids = similarProjectIds;
    args.isSimilaritySearch = true;
  }
  return args;
};

export const addQueryToPath = (path: string, query: Record<string, string>) => {
  const queryString = Object.keys(query)
    .map((key) => `${key}=${query[key]}`)
    .join("&");

  return `${path}?${queryString}`;
};

export const SimilaritySearchDefaultValues = {
  matchType: SimilaritySearchMatchTypes.Similar,
  weight: 50,
  isDefault: false,
};

export const getMatchTypeOptions = (isArray = false) => {
  let options = [
    {
      label: "Exact match",
      value: SimilaritySearchMatchTypes.Exact,
    },
    {
      label: "Similar",
      value: SimilaritySearchMatchTypes.Similar,
    },
    {
      label: "Contains all",
      value: SimilaritySearchMatchTypes.ContainsAll,
    },
  ];

  if (!isArray) {
    options = options.filter((option) => option.value !== "containsAll");
  }
  return options;
};

export const generateSimilarityComponents = (
  componentsByIds: Record<string, any>,
  similaritySearchConfig: Record<
    string,
    Record<
      string,
      {
        isDefault?: boolean;
      }
    >
  >,
) => {
  let stack = Object.values(similaritySearchConfig);
  const similarityComponents: any[] = [];

  while (stack.length > 0) {
    const form = stack.pop();

    if (form) {
      Object.keys(form).forEach((key) => {
        const configComponent = form[key];
        const component = componentsByIds[key];

        if (Boolean(configComponent?.isDefault) && component) {
          similarityComponents.push(component);
        }
      });
    }
  }

  return similarityComponents;
};

export const getSliderAttachments = (
  attachments: any[],
  galleryUrl?: string,
) => {
  const sliderAttachments: any[] = [];

  attachments.forEach((a) => {
    if (Boolean(a?.formValues?.slider?.show) || a.url === galleryUrl) {
      sliderAttachments.push(a);
    }
  });

  return sortBy(sliderAttachments, [
    function (a) {
      return a.formValues?.slider?.order;
    },
  ]);
};

interface GetMissingQuantitiesProps {
  formValues: any;
  componentByCode: Record<string, any>;
  groupedCostGroupComponents?: any[];
  costGroupQuantityComponents?: any[];
}

export const getCostGroupQuantityComponents = (
  formValues: any,
  costGroupQuantityComponents: any[] = [],
) => {
  if (!Boolean(formValues?.estimationMode)) {
    return costGroupQuantityComponents.filter(isBgfComponent);
  }
  return costGroupQuantityComponents;
};

const getSelectedCostComponents = ({
  formValues,
  componentByCode,
  groupedCostGroupComponents,
}: GetMissingQuantitiesProps) => {
  const selectedQuantityComponents: string[] = [];
  const { costGroups } = getAllCostGroups({
    groupedCostGroupComponents,
    depth: formValues?.depth ?? 1,
    selectedCostGroups: formValues?.selectedCostGroups ?? [],
  });

  costGroups.forEach((key) => {
    const quantityComponent =
      componentByCode[key]?.properties?.referenceQuantity;

    if (
      quantityComponent &&
      !selectedQuantityComponents.includes(quantityComponent)
    ) {
      selectedQuantityComponents.push(quantityComponent);
    }
  });
  return selectedQuantityComponents;
};

export const getMissingQuantities = ({
  formValues,
  componentByCode,
  costGroupQuantityComponents = [],
  groupedCostGroupComponents = [],
}: GetMissingQuantitiesProps) => {
  if (!Boolean(formValues?.estimationMode)) {
    return costGroupQuantityComponents
      .filter(isBgfComponent)
      .map((c) => c.id)
      .filter((id) => !Number.isFinite(formValues?.considerationValues?.[id]));
  }

  const selectedQuantityComponents = getSelectedCostComponents({
    formValues,
    componentByCode,
    costGroupQuantityComponents,
    groupedCostGroupComponents,
  });

  return selectedQuantityComponents.filter(
    (c) => !Number.isFinite(formValues?.considerationValues?.[c]),
  );
};

export const getConsiderationDate = (
  considerationDate: string,
  latestBKIDate?: string,
) => {
  if (!latestBKIDate) return considerationDate;

  return DayJs(considerationDate).isAfter(latestBKIDate)
    ? latestBKIDate
    : considerationDate;
};
