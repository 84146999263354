import { useSettingsStore, useUsersStore } from "@amenda-domains/mutations";
import { IconButtonBase, Tooltip } from "@amenda-components/App";
import { PlusIcon, SheetIcon } from "lucide-react";
import { GeneralPermissionTypes } from "@amenda-types";
import {
  GeneralPermissionKeys,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { ContactDirectoryProps } from "./types";
import { FC } from "react";
import { useParams } from "react-router-dom";

export const ContactListFooter: FC<ContactDirectoryProps> = (canCreateUser) => {
  const setCreateContactModal = useUsersStore(
    (state) => state.setCreateContactModal,
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const { userId } = useParams<{ userId: string }>();
  const setOpenUsersTableView = useUsersStore(
    (state) => state.setOpenUsersTableView,
  );
  const handleOpenUsersTableView = () => {
    setOpenUsersTableView(true);
  };

  const handleOpenModal = () => {
    setCreateContactModal(true);
  };

  const contactsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Contacts,
  );

  return (
    <div className="px-4 py-2 flex justify-end w-full sticky bottom-0">
      <div className="flex flex-col space-y-2">
        <div className="mt-0 flex items-center space-x-2">
          {!userId && (
            <div className="flex justify-end">
              <div className="px-2">
                <Tooltip id="tableView" message="Open table view">
                  <IconButtonBase
                    className="p-1 hidden lg:block"
                    variant="secondary"
                    size="md"
                    label="Open table view"
                    onClick={handleOpenUsersTableView}
                  >
                    <SheetIcon className="h-5 w-5" />
                  </IconButtonBase>
                </Tooltip>
              </div>
              {canCreateUser &&
                contactsGeneralPermissions[GeneralPermissionTypes.Create] && (
                  <div className="flex">
                    <Tooltip id="newContact" message="Add new Contact">
                      <IconButtonBase
                        className="p-1"
                        variant="round"
                        label="Open table view"
                        onClick={handleOpenModal}
                      >
                        <PlusIcon className="h-6 w-6 text-white" />
                      </IconButtonBase>
                    </Tooltip>
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
