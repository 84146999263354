import { Control, UseFormSetValue } from "react-hook-form";
import { getComponentsFromForms, groupComponents } from "@amenda-utils";

import { AllowedContactType } from "@amenda-types";
import { ComponentsTreeView } from "@amenda-components/PageBuilder";
import { FC } from "react";
import { SettingsPermissionConfig } from "@amenda-components/Settings";
import { permissionColumns } from "./common";
import { useProjectStore } from "@amenda-domains/mutations";

interface Props {
  control: Control;
  tab: {
    value: AllowedContactType;
    label: string;
  };
  globalData: Record<string, any>;
  setValue: UseFormSetValue<any>;
}

export const SettingsContactsPermissions: FC<Props> = ({
  tab,
  control,
  globalData,
  setValue,
}) => {
  const formsByContactType = useProjectStore(
    (state) => state.formsByContactType,
  );
  const components = getComponentsFromForms(formsByContactType[tab.value]);
  const groupedComponents = groupComponents(components);

  return (
    <ComponentsTreeView
      readOnly={false}
      control={control}
      setValue={setValue}
      globalData={globalData}
      components={groupedComponents}
      columns={permissionColumns}
      searchPlaceholder="Filter..."
      className="max-h-full h-[calc(100vh-8rem)]"
      RowBody={SettingsPermissionConfig}
    />
  );
};
