import { FC, useEffect, useMemo } from "react";
import {
  FormAutoSaveProvider,
  FormAutoSaveSubmitProps,
} from "@amenda-components/PageBuilder";
import {
  GeneralPermissionKeys,
  PermissionComponents,
  getFromGeneralPermissions,
} from "@amenda-components/Settings/common";
import { MainContainer, SidebarToggle } from "@amenda-components/Shared";
import {
  ProjectActionBar,
  ProjectFormTabs,
  ProjectSectionSidebar,
  ProjectSectionSidebarMobile,
} from "@amenda-components/Projects";
import { getQueryParams, getValidationWithPermissions } from "@amenda-utils";
import {
  getStaticProjectTabs,
  transformFormToProject,
  transformProjectToForm,
} from "./common";
import {
  useAppStore,
  useCreateProject,
  useProjectStore,
  useSettingsStore,
  useUpdateProject,
} from "@amenda-domains/mutations";

import { PermissionComponentKey } from "@amenda-constants";
import { isEmpty } from "lodash";
import { processFormPermissions } from "@amenda-components/Shared/common";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface Props {
  projectId?: string;
}

export const CreateOrEditProject: FC<Props> = ({ projectId }) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { createProject } = useCreateProject();
  const { updateProject } = useUpdateProject();
  const selectedProject = useProjectStore((state) => state.selectedProject);
  const setOpenSidebar = useAppStore((state) => state.setOpenSidebar);
  const sidebarOpen = useAppStore((state) => state.isSidebarOpen);
  const setIsSavingProject = useProjectStore(
    (state) => state.setIsSavingProject,
  );
  const permissions = useSettingsStore(
    (state) => state.currentUserSystemRole?.permissions || {},
  );
  const projectForms = useProjectStore((state) => state.projectForms);
  const isFetchingProject = useProjectStore((state) => state.isFetchingProject);

  const resourceId = projectId || selectedProject?.id;
  const projectsGeneralPermissions = getFromGeneralPermissions(
    permissions,
    GeneralPermissionKeys.Projects,
  );
  const availableForms = useMemo(() => projectForms || [], [projectForms]);
  const availableTabs = useMemo(() => {
    const tabs =
      availableForms?.map(({ name, id, order }) => ({
        value: id,
        label: name,
      })) || [];

    tabs.push(...getStaticProjectTabs(projectsGeneralPermissions));

    return tabs;
  }, [availableForms, projectsGeneralPermissions]);

  const query = getQueryParams(search);
  const values = transformProjectToForm(selectedProject);
  const defaultShareType =
    projectsGeneralPermissions?.[PermissionComponentKey]?.[
      PermissionComponents.GeneralAccess
    ];
  const defaultTab = query.tab || availableTabs[0].value;
  const components =
    availableForms.find((form) => form.id === defaultTab)?.components || [];
  const validationSchema = getValidationWithPermissions(
    components,
    processFormPermissions(permissions),
  );

  const onSubmit = async ({
    dirtyData,
    resourceId,
  }: FormAutoSaveSubmitProps) => {
    const handleSave = resourceId ? updateProject : createProject;
    const input = transformFormToProject({
      resourceId,
      defaultShareType,
      form: dirtyData,
    });

    if (!isEmpty(dirtyData)) {
      setIsSavingProject(true);
      await handleSave({
        input,
      });
      setIsSavingProject(false);
    }
  };

  useEffect(() => {
    if (sidebarOpen) {
      setOpenSidebar(false);
    }
  }, [sidebarOpen, setOpenSidebar]);

  return (
    <FormAutoSaveProvider
      values={values}
      resourceId={resourceId}
      inputSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <div className="flex flex-row w-full h-full">
        <ProjectSectionSidebar
          resourceId={resourceId}
          availableForms={availableForms}
          projectsGeneralPermissions={projectsGeneralPermissions}
        />
        <ProjectSectionSidebarMobile
          resourceId={resourceId}
          availableForms={availableForms}
          projectsGeneralPermissions={projectsGeneralPermissions}
        />
        <MainContainer className="pt-6">
          <div className="w-full h-screen overflow-y-auto overscroll-y-contain pr-4">
            {!isFetchingProject && (
              <div className="sticky top-0 z-30 bg-white w-full">
                <div className="flex justify-between items-center w-full">
                  <div className="w-full flex items-center">
                    <SidebarToggle />
                    <h3 className="amenda-page-title truncate">
                      {selectedProject?.name || t("New Project")}
                    </h3>
                  </div>
                  <div className="flex justify-end">
                    <ProjectActionBar isEditing={true} />
                  </div>
                </div>
              </div>
            )}
            <div className="h-auto w-full pb-20 lg:pb-8">
              <ProjectFormTabs
                defaultTab={defaultTab}
                projectId={resourceId}
                projectForms={availableForms}
                projectFormTabs={availableTabs}
              />
            </div>
          </div>
        </MainContainer>
      </div>
    </FormAutoSaveProvider>
  );
};
