import { BKIModalButton, RegionalSelect } from "@amenda-components/Shared";
import {
  Badges,
  DateQuarterPicker,
  Switch,
} from "@amenda-components/FormComponents";
import { Control, Controller } from "react-hook-form";

import { FC } from "react";
import { InfoIcon } from "lucide-react";
import { Tooltip } from "@amenda-components/App";
import clsx from "clsx";
import { depthOptions } from "./common";
import { isEmpty } from "lodash";
import { useProjectStore } from "@amenda-domains/mutations";
import { useTranslation } from "react-i18next";

export const CostEstimationForm: FC<{
  control: Control<any>;
}> = ({ control }) => {
  const { t } = useTranslation();
  const similaritySearchProjects = useProjectStore(
    (state) => state.similaritySearchProjects,
  );

  return (
    <div className="p-4 space-y-3">
      <Controller
        name="depth"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <div className="min-w-[12rem]">
              <Badges
                label="Depth"
                isMulti={false}
                badgeClassName=""
                options={depthOptions}
                selectedOptions={value || []}
                error={error?.message}
                onChange={onChange}
              />
            </div>
          );
        }}
      />
      <Controller
        name="calculation"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <div
              className={clsx("min-w-[12rem]", {
                hidden: isEmpty(similaritySearchProjects),
              })}
            >
              <div className="flex flex-row">
                <span className="text-gray-500">{t("Calculation")}</span>
                <Tooltip
                  id="calculationMode"
                  message={t("Use the projects weights for the calculation")}
                >
                  <InfoIcon className="w-4 h-4 text-gray-500 m-1" />
                </Tooltip>
              </div>
              <div className="w-full flex flex-col pt-2">
                <Switch
                  checked={Boolean(value)}
                  label="Weighted"
                  onChange={onChange}
                />
              </div>
            </div>
          );
        }}
      />
      <Controller
        name="estimationMode"
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <div className="min-w-[12rem]">
              <div className="flex flex-row">
                <span className="text-gray-500">
                  {t("Estimate with all reference quantities?")}
                </span>
                <Tooltip
                  id="estimationMode"
                  message={t(
                    "Uses all reference quantities within the estimation",
                  )}
                >
                  <InfoIcon className="w-4 h-4 text-gray-500 m-1" />
                </Tooltip>
              </div>
              <div className="w-full flex flex-col pt-2">
                <Switch
                  checked={Boolean(value)}
                  label="Yes"
                  onChange={onChange}
                />
              </div>
            </div>
          );
        }}
      />
      <Controller
        name="considerationDate"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <div className="min-w-[12rem] pt-4">
              <DateQuarterPicker
                id="considerationDate"
                label="Stand der Kostenermittlung"
                value={value}
                error={error?.message}
                optionalLabel={<BKIModalButton />}
                onChange={onChange}
              />
            </div>
          );
        }}
      />
      <Controller
        control={control}
        name="region"
        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <RegionalSelect
              id="region"
              label="Calculation for region"
              regionId={value?.id}
              error={error?.message}
              onChange={onChange}
            />
          );
        }}
      />
    </div>
  );
};
